import "@/globals";
import { useCalibrationCurves, useTranslation } from "@hooks";
import { useBackendPathCollection } from "@hooks/project/useBackendPathCollection";
import { Project } from "@models/backend";
import { Model, PathCollection, PathCollectionInput } from "@models/project";
import { useModelsStore } from "@state/models";
import { PathAction, PathActionType } from "@state/models/reducers/pathReducer";
import { Curve, Vector3 } from "@variant-tech/pattern-derivation";
import { useCallback, useMemo } from "react";

export function usePath(project: Project, model: Model, pathCollection: PathCollectionInput | PathCollection) {
  const {
    changePathCollection,
    changePathCollectionNew,
    clearNewPathCollection,
    pushPathCollection,
    dispatchPathNew,
    dispatchPathEdit,
    removePathCollection,
    setKnitMeshDirty,
    pathCollections,
  } = useModelsStore();

  const { createBackendPathCollection, updateBackendPathCollections, deleteBackendPathCollection } =
    useBackendPathCollection();

  const { generateGridCurves } = useCalibrationCurves({ project, model });
  const { t } = useTranslation("project.sidebar.layers");

  const noGuide = useMemo(() => {
    const collections = pathCollections[model.id].collections;
    for (const id in collections) {
      if (collections[id].usage === "guide") {
        return false;
      }
    }
    return true;
  }, [pathCollections, model.id]);

  const dispatch = useCallback(
    (action: PathAction) => {
      if ("id" in pathCollection) {
        return dispatchPathEdit(model.id, pathCollection.id, action);
      } else {
        return dispatchPathNew(model.id, action);
      }
    },
    [model, pathCollection, dispatchPathNew, dispatchPathEdit],
  );

  const updateAfterPathChange = useCallback(
    async (collections: PathCollection[]) => {
      const guideCurve = collections.find((collection) => collection.usage === "guide");

      if (guideCurve) {
        const numAutoColumns = model.attributes.columns?.count || 0;
        const numAutoSections = model.attributes.sections?.count || 0;

        await generateGridCurves(guideCurve, numAutoSections, numAutoColumns);
      }

      await updateBackendPathCollections(project.id, model.id, collections);
    },
    [model, generateGridCurves, project.id],
  );

  const updatePathCollection = useCallback(
    async (input: PathCollectionInput) => {
      if ("id" in pathCollection) {
        const result = changePathCollection(model.id, pathCollection.id, input);
        await updateAfterPathChange(result);
      } else {
        changePathCollectionNew(model.id, input);
      }
    },
    [model, pathCollection, changePathCollection, changePathCollectionNew, updateAfterPathChange],
  );

  const appendPoint = useCallback(
    async (point: Vector3) => {
      const updated = dispatch({ type: PathActionType.ADD_LAST_POINT, point });
      await updatePathCollection(updated);
      return updated;
    },
    [model, dispatch, updatePathCollection],
  );

  const insertPoint = useCallback(
    async (point: Vector3, index: number) => {
      const updated = dispatch({ type: PathActionType.INSERT_POINT, point, index });
      await updatePathCollection(updated);
      return updated;
    },
    [model, dispatch, updatePathCollection],
  );

  const removePath = useCallback(async () => {
    if ("id" in pathCollection) {
      removePathCollection(model.id, pathCollection.id);
      await deleteBackendPathCollection(project.id, model.id, pathCollection.id);
      setKnitMeshDirty(true);
    } else {
      clearNewPathCollection(model.id);
    }
  }, [model, pathCollection, project.id]);

  const removePoints = useCallback(
    async (orig: number[]) => {
      const indexes = orig.slice();
      indexes.sort((a, b) => b - a);
      const updated = dispatch({ type: PathActionType.REMOVE_POINTS, indexes });
      await updatePathCollection(updated);
      setKnitMeshDirty(true);
      return updated;
    },
    [model, dispatch, updatePathCollection],
  );

  const close = useCallback(async () => {
    const updated = dispatch({ type: PathActionType.CLOSE });
    await updatePathCollection(updated);
    return updated;
  }, [model, dispatch, updatePathCollection]);

  const updatePoint = useCallback(
    async (index: number, point: Vector3) => {
      const updated = dispatch({ type: PathActionType.MOVE_POINT, index, point });
      await updatePathCollection(updated);
      setKnitMeshDirty(true);
      return updated;
    },
    [model, dispatch, updatePathCollection],
  );

  const changeVector = useCallback(
    async (index: number, vector: Vector3) => {
      const updated = dispatch({ type: PathActionType.CHANGE_VECTOR, index, vector });
      await updatePathCollection(updated);
      setKnitMeshDirty(true);
      return updated;
    },
    [model, dispatch, updatePathCollection],
  );

  const removeVector = useCallback(
    async (index: number) => {
      if (pathCollection.type !== "BezierPath") throw new Error("Can't remove vector from a non-bezier Path");
      const updated = dispatch({ type: PathActionType.REMOVE_VECTOR, index });
      await updatePathCollection(updated);
      setKnitMeshDirty(true);
      return updated;
    },
    [model, pathCollection, dispatch, updatePathCollection],
  );

  const getNormalAndTangent = useCallback(
    (curve: Curve, index: number) => {
      if (curve.normalsAndTangents.length > index) {
        return curve.normalsAndTangents[index];
      }
      return { first: [0, 0, 0] as Vector3, second: [0, 0, 0] as Vector3 };
    },
    [pathCollection],
  );

  const toggleBend = useCallback(
    async (curve: Curve, index: number) => {
      if (pathCollection.controlVectors?.[index]?.[0]?.some((v) => v !== 0) ?? false) {
        await removeVector(index);
      } else {
        const tangent = curve.normalsAndTangents[index]?.second;
        if (tangent) {
          await changeVector(index, tangent);
        }
      }
    },
    [pathCollection, changeVector, removeVector],
  );

  const pushPath = useCallback(
    async (closePath?: boolean) => {
      if (closePath) {
        await close();
      }
      const usage = closePath ? "zone" : noGuide ? "guide" : null;
      const idx = Object.keys(pathCollections[model.id]?.collections ?? {}).length;
      const layerName = (closePath ? t("types.closed") : t("types.curve")) + ` ${idx}`;

      const newPath = changePathCollectionNew(model.id, { usage });
      const response = await createBackendPathCollection(project.id, model.id, {
        ...newPath,
        name: layerName,
        attributes: {},
      });

      pushPathCollection(model.id, { id: response.id, name: layerName, attributes: {} });

      if (newPath.usage === "guide") {
        const numAutoColumns = model.attributes.columns?.count || 0;
        const numAutoSections = model.attributes.sections?.count || 0;
        await generateGridCurves(newPath, numAutoSections, numAutoColumns);
      }
    },
    [model, project, pathCollections, close, noGuide],
  );

  const flipPath = useCallback(async () => {
    const updated: PathCollectionInput = {
      ...pathCollection,
      points: [...pathCollection.points].reverse(),
      controlVectors: pathCollection.controlVectors ? [...pathCollection.controlVectors].reverse() : [],
    };

    await updatePathCollection(updated);

    if ("id" in pathCollection) {
      setKnitMeshDirty(true);
    }
    return updated;
  }, [pathCollection, updatePathCollection]);

  return {
    pathCollection,
    removePath,
    appendPoint,
    insertPoint,
    removePoints,
    updatePoint,
    changeVector,
    removeVector,
    pushPath,
    getNormalAndTangent,
    flipPath,
    toggleBend,
  };
}
