import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

export function KnitMeshViewIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 20 20" height="5" width="5" {...props}>
      <path
        d="M10 19C6.06846 19 2.72571 16.4791 1.5 12.9655M10 19C13.9315 19 17.2743 16.4791 18.5 12.9655M10 19C10 19 5.5 16.75 5.5 10C5.5 3.25 10 1 10 1M10 1C5.02944 1 1 5.02944 1 10C1 11.039 1.17607 12.0369 1.5 12.9655M10 1C13.9187 1 17.2524 3.50442 18.4879 7M1.5 7H18.4879M18.4879 7C18.8195 7.93834 19 8.94809 19 10C19 11.039 18.8239 12.0369 18.5 12.9655M1.5 12.9655H18.5"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
      />
    </Icon>
  );
}
