import { IconProps } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";

export function AddCommentPlacementIcon({ fill = "currentColor", ...props }: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" width="16px" height="16px" {...props}>
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0V0C12.4183 0 16 3.58172 16 8V8C16 12.4183 12.4183 16 8 16H0V8Z"
        fill={fill as string}
      />
    </Icon>
  );
}
