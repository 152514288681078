import { useCallback } from "react";
import { Project } from "@models/backend";
import BackendApi from "@api/BackendApi";
import { useAuth } from "@auth";

export function useProjectUpdate(project: Project) {
  const { headers } = useAuth();

  const renameProject = useCallback(
    async (newName: string) => {
      try {
        const updatedProject = await BackendApi.patchProject({
          headers,
          params: { projectId: project.id },
          body: { name: newName },
        });
        return updatedProject;
      } catch (error) {
        console.error("Failed to patch project:", error);
        throw error;
      }
    },
    [project.id, headers],
  );

  const addFileShareURl = useCallback(
    async (fileShareUrl: string) => {
      try {
        const updatedProject = await BackendApi.patchProject({
          headers,
          params: { projectId: project.id },
          body: { fileShareUrl },
        });
        return updatedProject;
      } catch (error) {
        console.error("Failed to patch project:", error);
        throw error;
      }
    },
    [project.id, headers],
  );

  return {
    addFileShareURl,
    renameProject,
  };
}
