import { useSemanticTokens } from "@design-system";
import { DirectionalityHelper } from "./DirectionalityHelper";
import { usePath } from "@hooks";
import { PathCollection as PathCollectionBackend, Project } from "@models/backend";
import { Model } from "@models/project";
import { useModelsStore } from "@state/models";
import { OnClickParams } from "@utils";
import { getV3dApi } from "@utils/project/initV3dApi";
import { Curve, Vector3 } from "@variant-tech/pattern-derivation";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { BezHandles } from "./BezHandles";
import { Curve as CurveComponent } from "./curve";
import { SurfacePoint } from "./SurfacePoint";
import { workspace3dTokens } from "./workspace-3d-tokens";

const EMPTY_CURVE: Curve = {
  points: [],
  isLoop: false,
  controlVectors: [],
  segments: [],
  normalsAndTangents: [],
  type: "Path",
  samples: [],
  vertices: new Float32Array(),
};

export type PathProps = {
  project: Project;
  model: Model;
  collectionId?: string;
  collectionUsage?: PathCollectionBackend["usage"];
  isSelectionAllowed?: boolean;
  isHoverAllowed?: boolean;
  highViz?: boolean;
  onClick?: (params: OnClickParams) => void;
};

export function Path({
  project,
  model,
  collectionId,
  collectionUsage,
  isSelectionAllowed = true,
  isHoverAllowed = true,
  highViz = false,
  onClick,
}: PathProps) {
  const { hoveredObject, setHoveredObject, selectedObjects, setSelectedObjects, pathCollections } = useModelsStore();
  const pathCollection = useMemo(() => {
    return collectionId === undefined
      ? pathCollections[model.id].newCollection
      : pathCollections[model.id].collections[collectionId];
  }, [pathCollections, collectionId, model.id]);

  const { updatePoint, changeVector, removeVector, removePoints, removePath, toggleBend, getNormalAndTangent } =
    usePath(project, model, pathCollection);
  const [curve, setCurve] = useState<Curve>(EMPTY_CURVE);

  useEffect(() => {
    if (pathCollection.points.length > 0) {
      getV3dApi()
        .generateCurve(model.mesh3DBase, pathCollection)
        .then((curve) => setCurve(curve));
    } else {
      setCurve(EMPTY_CURVE);
    }
  }, [pathCollection, model.mesh3DBase]);

  const { points, isLoop, controlVectors, type, segments } = curve;

  const [hoveredPoint, setHoveredPoint] = useState<Vector3>();
  const [selectedPoints, setSelectedPoints] = useState<Record<number, boolean>>({});

  const style = workspace3dTokens.path[collectionUsage ?? "unused"];
  const defaultColor = style.color;
  const selectedColor = useSemanticTokens().border.accent.secondary;

  const { defaultWidth, hoveredWidth } = workspace3dTokens.path.lineWidth;

  const [color, setColor] = useState(defaultColor);
  const [lineWidth, setLineWidth] = useState(1.0);

  const isSelected = collectionId
    ? isSelectionAllowed && !!selectedObjects.find(({ id }) => id === collectionId)
    : true;
  const isNewPath = collectionId === undefined;
  const isHovered = !isSelected && isHoverAllowed && hoveredObject?.id === collectionId;
  const showPoints = isNewPath || isSelected || points.length === 1;
  const opacity = isHovered || isSelected ? 1.0 : style.opacity;

  useEffect(() => {
    if (isNewPath) return;
    if (isSelected || isHovered) {
      setColor(selectedColor);

      if (isHovered) {
        setLineWidth(highViz ? hoveredWidth * 3 : hoveredWidth);
      } else {
        setLineWidth(highViz ? defaultWidth * 3 : defaultWidth);
      }
    } else {
      setColor(defaultColor);
      setLineWidth(highViz ? defaultWidth * 3 : defaultWidth);
      setSelectedPoints({});
    }
  }, [selectedObjects, hoveredObject, isNewPath, isSelected, isHovered, selectedColor, defaultColor, highViz]);

  useHotkeys(["delete", "backspace"], async () => {
    await removeSelectedPoints();
  });

  useEffect(() => {
    setSelectedPoints({});
  }, [points]);

  const removeSelectedPoints = async () => {
    const toDelete: number[] = [];
    for (let i = points.length - 1; i >= 0; i--) {
      if (selectedPoints[i]) {
        toDelete.push(i);
      }
    }
    if (!isSelected && toDelete.length < 1) return;
    const removeAll = toDelete.length === points.length || (isSelected && toDelete.length === 0);
    if (removeAll) {
      await removePath();
    } else {
      await removePoints(toDelete);
    }
  };

  const pointViz = points.map((point, index) => {
    const neighbors: Vector3[] = [];
    if (points[index + 1] !== undefined) {
      neighbors.push(points[index + 1]);
    }
    if (points[index - 1] !== undefined) {
      neighbors.push(points[index - 1]);
    }
    if (index === 0 && isLoop) {
      neighbors.push(points[points.length - 1]);
    } else if (index === points.length - 1 && isLoop) {
      neighbors.push(points[0]);
    }
    return (
      <Fragment key={index}>
        <SurfacePoint
          hovered={hoveredPoint === point}
          setHovered={(hovered) => {
            if (hovered) {
              setHoveredPoint(point);
            } else {
              setHoveredPoint(undefined);
            }
          }}
          model={model}
          point={point}
          isVisible={showPoints}
          collectionId={collectionId}
          neighbors={neighbors}
          updatePoint={async (pt) => {
            await updatePoint(index, pt);
            if (collectionId) {
              setSelectedObjects([{ type: "path", id: collectionId, modelId: model.id }]);
            }
          }}
          setPointSelection={async (selected: boolean) => {
            const sel = { ...selectedPoints, [index]: selected };
            setSelectedPoints(sel);
          }}
          removePoint={async () => {
            const removeAll = points.length === 1;
            if (removeAll) {
              await removePath();
            } else {
              await removePoints([index]);
            }
          }}
          toggleBend={async () => {
            await toggleBend(curve, index);
            if (collectionId) {
              setSelectedObjects([{ type: "path", id: collectionId, modelId: model.id }]);
            }
          }}
        />
        {showPoints && type === "BezierPath" && (
          <BezHandles
            point={point}
            controlVectors={controlVectors[index]}
            getNormalAndTangent={() => getNormalAndTangent(curve, index)}
            mesh={model.mesh}
            changeVector={async (vector) => {
              if (vector) {
                await changeVector(index, vector);
                if (collectionId) {
                  setSelectedObjects([{ id: collectionId, type: "path", modelId: model.id }]);
                }
              } else {
                await removeVector(index);
              }
            }}
          />
        )}
      </Fragment>
    );
  });

  return (
    <>
      {segments.map((segment, index) => (
        <CurveComponent
          key={index}
          curve={segment.vertices}
          mesh={model.mesh}
          length={segment.length}
          color={color}
          opacity={opacity}
          width={lineWidth}
          isHovered={isHovered}
          isSelected={isSelected}
          onHovered={(hovered) => {
            if (!collectionId || !isHoverAllowed) return;
            if (hovered) {
              setHoveredObject({
                type: "path",
                id: collectionId,
                modelId: model.id,
                segmentIdx: index,
                curveSegment: segment,
              });
            } else {
              setHoveredObject(null);
            }
          }}
          onSelected={(e) => {
            if (collectionId) {
              if (isSelectionAllowed) {
                setSelectedObjects([{ type: "path", id: collectionId, modelId: model.id }]);
              }
              onClick?.({ ...e, id: collectionId });
            }
          }}
          userData={{ type: "path", id: collectionId, modelId: model.id }}
        />
      ))}
      {pointViz}
      {pathCollection.usage === "guide" && (
        <DirectionalityHelper
          model={model}
          pathCollection={pathCollection}
          curve={curve}
          isHovered={isHovered}
          isSelected={isSelected}
        />
      )}
    </>
  );
}
