import { resolveHexValue, useSemanticTokens, utility } from "@design-system";
import {
  BezHandlesProps,
  Hovered,
  MIN_CHANGE,
  Moving,
  vectorHandles,
} from "@fragments/project/workspace-3d/bezHandlesTypes";
import { usePointerState } from "@hooks/project/usePointerState";
import { Billboard, Circle, Line, ScreenSizer } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { CPair as CP, Vector3 as VP } from "@variant-tech/pattern-derivation";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DoubleSide, Group, Mesh, MeshBasicMaterial, Plane, PlaneGeometry, Vector3 } from "three";
import { Line2 } from "three/examples/jsm/lines/Line2.js";
import { useOcclusionChecker } from "./useOcclusionChecker";
import { workspace3dTokens } from "./workspace-3d-tokens";

export function BezHandles({
  point,
  controlVectors,
  getNormalAndTangent,
  mesh,
  changeVector,
  initialMoving,
}: BezHandlesProps) {
  const [normalAndTangent, setNormalAndTangent] = useState<CP<VP, VP> | null>(null);
  const [handleHovered, setHandleHovered] = useState<Hovered | null>(null);
  const [moving, setMoving] = useState<Moving | null>(initialMoving ?? null);

  const { checkForOcclusion } = useOcclusionChecker({ mesh });

  // const [planeDebug, setPlaneDebug] = useState<Mesh | null>(null);

  const { raycaster, camera, mouse } = useThree();

  const { pointer0Down } = usePointerState();

  const accentTertiary = resolveHexValue(useSemanticTokens().icons.accent.tertiary);
  const outerRadius = workspace3dTokens.bezierHandle.outerRadius;
  const innerRadius = workspace3dTokens.bezierHandle.innerRadius;

  useEffect(() => {
    setNormalAndTangent(getNormalAndTangent());
  }, [getNormalAndTangent]);
  const handles = useMemo(() => {
    if (normalAndTangent?.first.some((val) => Number.isNaN(val))) {
      console.log("NaN in normal");
      return [];
    }
    if (normalAndTangent?.second.some((val) => Number.isNaN(val))) {
      // TODO: deleting surface points from a closed pathcollection before it has its normal and tangent calculated
      // results in NaN in the tangent array; not returning early results in a computeBoundingSphere error below
      console.log("NaN in tangent");
      return [];
    }
    return normalAndTangent ? vectorHandles(point, controlVectors, normalAndTangent) : [];
  }, [point, controlVectors, normalAndTangent]);

  const changeVectorEnd = useCallback(async () => {
    if (!normalAndTangent) return;

    const { first: normal } = normalAndTangent;
    const normalVec = new Vector3(...normal);
    const origin = new Vector3(...point);
    const planeConstant = normalVec.dot(origin);
    const plane = new Plane(normalVec, -planeConstant);

    raycaster.setFromCamera(mouse, camera);

    const intersectionPoint = new Vector3();
    raycaster.ray.intersectPlane(plane, intersectionPoint);

    if (!intersectionPoint) {
      return;
    }

    const vector = intersectionPoint.clone();
    vector.sub(origin);
    vector.projectOnPlane(normalVec);
    const chLength = vector.length();
    if (chLength > MIN_CHANGE) {
      if (moving === Moving.VECTOR_LEFT) {
        await changeVector([-vector.x, -vector.y, -vector.z]);
      } else if (moving === Moving.VECTOR_RIGHT) {
        await changeVector([vector.x, vector.y, vector.z]);
      }
    } else {
      await changeVector([0, 0, 0]);
    }
    setMoving(null);
  }, [normalAndTangent, changeVector, raycaster, moving, setMoving, camera, mouse]);
  const [pointerPos, setPointerPos] = useState<Vector3 | undefined>(new Vector3());

  useEffect(() => {
    if (pointer0Down && groupRef.current?.visible) {
      if (!normalAndTangent) return;

      raycaster.setFromCamera(mouse, camera);

      const intersectionPoint = new Vector3();

      const { first: normal } = normalAndTangent;
      const normalVec = new Vector3(...normal);
      const origin = new Vector3(...point);

      const planeConstant = normalVec.dot(origin);
      const plane = new Plane(normalVec, -planeConstant);
      raycaster.ray.intersectPlane(plane, intersectionPoint);

      // Create the PlaneGeometry and Mesh
      const planeGeometry = new PlaneGeometry(20, 20);
      const material = new MeshBasicMaterial({ color: 0x00ff00, side: DoubleSide, transparent: true, opacity: 0.25 });
      const planeMesh = new Mesh(planeGeometry, material);

      // Position the mesh at a point on the plane
      const pointOnPlane = plane.normal.clone().multiplyScalar(-plane.constant);
      planeMesh.position.copy(pointOnPlane);

      // Determine a point in the direction of the plane's normal to use with lookAt
      const lookAtTarget = plane.normal.clone().add(planeMesh.position);

      // Use lookAt to orient the mesh's +Z axis towards the target
      planeMesh.lookAt(lookAtTarget);

      // setPlaneDebug(planeMesh);

      if (!intersectionPoint) {
        return;
      }
      if (moving !== null) {
        return;
      }
      if (handleHovered === Hovered.VECTOR_LEFT) {
        setMoving(Moving.VECTOR_LEFT);
      } else if (handleHovered === Hovered.VECTOR_RIGHT) {
        setMoving(Moving.VECTOR_RIGHT);
      }
    } else {
      if (moving == Moving.VECTOR_LEFT || moving == Moving.VECTOR_RIGHT) {
        changeVectorEnd();
      }
    }
  }, [pointer0Down, normalAndTangent]);

  const leftMatRef = useRef<MeshBasicMaterial>(null);
  const rightMatRef = useRef<MeshBasicMaterial>(null);
  const leftLineRef = useRef<Line2>(null);
  const rightLineRef = useRef<Line2>(null);
  const groupRef = useRef<Group>(null);

  useFrame(() => {
    function onChange(occluded: boolean) {
      if (groupRef.current) {
        groupRef.current.visible = !occluded;
      }
    }

    checkForOcclusion(point, onChange);

    if (!normalAndTangent || !groupRef.current?.visible) return;

    const { first: normal } = normalAndTangent;
    const normalVec = new Vector3(...normal);
    const origin = new Vector3(...point);
    const planeConstant = normalVec.dot(origin);
    const plane = new Plane(normalVec, -planeConstant);

    raycaster.setFromCamera(mouse, camera);

    const intersectionPoint = new Vector3();
    raycaster.ray.intersectPlane(plane, intersectionPoint);

    if (intersectionPoint) {
      setPointerPos(intersectionPoint);
    }
  });

  useEffect(() => {
    setPointerPos(undefined);
  }, [point]);

  function getHandleColors() {
    if (handleHovered === Hovered.VECTOR_LEFT || moving === Moving.VECTOR_LEFT) {
      return [accentTertiary, utility.white];
    } else if (handleHovered === Hovered.VECTOR_RIGHT || moving === Moving.VECTOR_RIGHT) {
      return [utility.white, accentTertiary];
    } else {
      return [utility.white, utility.white];
    }
  }

  const [handle0Color, handle1Color] = getHandleColors();

  const circleOpacity = moving ? 0.5 : 1.0;

  const vectorsViz = () => {
    if (!handles.length || (!initialMoving && (controlVectors?.[0]?.every((v) => v === 0) ?? true))) {
      return null;
    }
    const [lpos, rpos] = handles;
    const origin = new Vector3(...point);

    return (
      <group ref={groupRef}>
        <Billboard position={lpos} follow={true}>
          <ScreenSizer scale={1}>
            <Circle
              args={[outerRadius, 16]}
              position={0}
              renderOrder={Infinity}
              onPointerEnter={() => {
                setHandleHovered(Hovered.VECTOR_LEFT);
              }}
              onPointerLeave={() => {
                setHandleHovered(null);
              }}
            >
              <meshBasicMaterial
                color={handle0Color}
                opacity={circleOpacity}
                transparent
                depthTest={false}
                ref={leftMatRef}
              />
            </Circle>
            <Circle args={[innerRadius, 16]} position={0} renderOrder={Infinity}>
              <meshBasicMaterial
                color={accentTertiary}
                opacity={circleOpacity}
                transparent
                depthTest={false}
                ref={leftMatRef}
              />
            </Circle>
          </ScreenSizer>
        </Billboard>

        {!moving && (
          <Line
            points={[lpos, origin]}
            color={accentTertiary}
            lineWidth={2}
            depthTest={false}
            renderOrder={Infinity}
            opacity={0.75}
            transparent
            ref={leftLineRef}
          />
        )}
        {!moving && (
          <Line
            points={[rpos, origin]}
            color={accentTertiary}
            lineWidth={2}
            depthTest={false}
            renderOrder={Infinity}
            opacity={0.75}
            transparent
            ref={rightLineRef}
          />
        )}

        <Billboard position={rpos} follow={true}>
          <ScreenSizer scale={1}>
            <Circle
              args={[outerRadius, 16]}
              position={0}
              renderOrder={Infinity}
              onPointerOver={() => {
                setHandleHovered(Hovered.VECTOR_RIGHT);
              }}
              onPointerLeave={() => {
                setHandleHovered(null);
              }}
            >
              <meshBasicMaterial
                color={handle1Color}
                opacity={circleOpacity}
                transparent
                depthTest={false}
                ref={rightMatRef}
              />
            </Circle>
            <Circle args={[innerRadius, 16]} position={0} renderOrder={Infinity}>
              <meshBasicMaterial
                color={accentTertiary}
                opacity={circleOpacity}
                transparent
                depthTest={false}
                ref={leftMatRef}
              />
            </Circle>
          </ScreenSizer>
        </Billboard>
      </group>
    );
  };
  const movingsViz = () => {
    if (!normalAndTangent || !pointerPos) return null;
    const vector = pointerPos.clone();
    const origin = new Vector3(...point);
    vector.sub(origin);
    const normal = normalAndTangent.first;
    vector.projectOnPlane(new Vector3(...normal));
    const lmov = origin.clone();
    const rmov = origin.clone();
    if (moving === Moving.VECTOR_LEFT) {
      lmov.add(vector);
      rmov.sub(vector);
    } else if (moving === Moving.VECTOR_RIGHT) {
      lmov.sub(vector);
      rmov.add(vector);
    }

    const [lpos, rpos] = handles;
    const [posLeft, posRight] = pointer0Down ? [lmov, rmov] : [lpos, rpos];

    return (
      <>
        <Billboard position={posLeft} follow={true}>
          <ScreenSizer scale={1}>
            <Circle args={[outerRadius, 16]} position={0} renderOrder={Infinity}>
              <meshBasicMaterial color={handle0Color} opacity={1.0} transparent depthTest={false} ref={rightMatRef} />
            </Circle>
            <Circle args={[innerRadius, 16]} position={0} renderOrder={Infinity}>
              <meshBasicMaterial color={accentTertiary} opacity={1.0} transparent depthTest={false} ref={leftMatRef} />
            </Circle>
          </ScreenSizer>
        </Billboard>

        <Line
          points={[origin, posLeft]}
          color={accentTertiary}
          lineWidth={2}
          depthTest={false}
          renderOrder={Infinity}
          opacity={1.0}
          transparent
        />
        <Line
          points={[origin, posRight]}
          color={accentTertiary}
          lineWidth={2}
          depthTest={false}
          renderOrder={Infinity}
          opacity={1.0}
          transparent
        />

        <Billboard position={posRight} follow={true}>
          <ScreenSizer scale={1}>
            <Circle args={[outerRadius, 16]} position={0} renderOrder={Infinity}>
              <meshBasicMaterial color={handle1Color} opacity={1.0} transparent depthTest={false} ref={rightMatRef} />
            </Circle>
            <Circle args={[innerRadius, 16]} position={0} renderOrder={Infinity}>
              <meshBasicMaterial color={accentTertiary} opacity={1.0} transparent depthTest={false} ref={leftMatRef} />
            </Circle>
          </ScreenSizer>
        </Billboard>
      </>
    );
  };

  // const planeDebugViz = planeDebug && moving ? <primitive object={planeDebug} /> : null;

  return (
    <>
      {vectorsViz()}
      {moving && movingsViz()}
      {/* {planeDebugViz} */}
    </>
  );
}
