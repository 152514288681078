import { MenuItem } from "@chakra-ui/react";
import { IconDropdown, IconDropdownProps, Separator } from "@design-system";
import { RenameProjectPanel, FileShareInputPanel } from "@fragments/projects/view";
import { useDeleteProject, useModelName, useProjectUpdate, useProjectUrl, useTranslation } from "@hooks";
import { Project } from "@models/backend";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { MoreIcon } from "./icons";

export function ProjectContextMenu({
  project,
  onProjectUpdated,
  onProjectDeleted,
  ...iconDropdownProps
}: {
  project: Project;
  onProjectUpdated?: (updatedProject: Project) => void;
  onProjectDeleted?: (updatedProject: Project) => void;
} & Partial<IconDropdownProps>) {
  const { t: tContextMenu } = useTranslation("home.projects.projectContextMenu.attributes");
  const { path, onCopy } = useProjectUrl(project.id);
  const { deleteProject } = useDeleteProject();
  const { renameProject, addFileShareURl } = useProjectUpdate(project as Project);
  const displayName = useModelName(project as Project);

  const [isRenameOpen, setIsRenameOpen] = useState(false);
  const [isFileShareInputOpen, setIsFileShareInputOpen] = useState(false);

  const handleRename = async (newName: string): Promise<Project> => {
    const updatedProject = await renameProject(newName);
    onProjectUpdated?.(updatedProject);
    setIsRenameOpen(false);
    return updatedProject;
  };

  const handleAddFileShareUrl = async (fileShareUrl: string): Promise<Project> => {
    const updatedProject = await addFileShareURl(fileShareUrl);
    onProjectUpdated?.(updatedProject);
    setIsFileShareInputOpen(false);
    return updatedProject;
  };

  return (
    <>
      <IconDropdown
        label="more"
        icon={<MoreIcon />}
        variant="contextMenu"
        {...iconDropdownProps}
        data-test-id="project-context-menu"
      >
        <MenuItem as={NavLink} to={path}>
          {tContextMenu("open.label")}
        </MenuItem>
        <MenuItem as={NavLink} target="_blank" to={path}>
          {tContextMenu("openInNewTab.label")}
        </MenuItem>
        <MenuItem onClick={() => setIsRenameOpen(true)}>{tContextMenu("rename.label")}</MenuItem>
        <Separator p={0} m={0} variant="inverse" />
        {project.fileShareUrl ? (
          <>
            <MenuItem onClick={() => window.open(project.fileShareUrl, "_blank")}>
              {tContextMenu("openAssetsFolder.label")}
            </MenuItem>
            <MenuItem onClick={() => setIsFileShareInputOpen(true)}>
              {tContextMenu("editAssetsFolderLink.label")}
            </MenuItem>
          </>
        ) : (
          <MenuItem onClick={() => setIsFileShareInputOpen(true)}>{tContextMenu("attachAssetsFolder.label")}</MenuItem>
        )}
        <Separator p={0} m={0} variant="inverse" />
        <MenuItem onClick={onCopy}>{tContextMenu("copyLink.label")}</MenuItem>
        <Separator p={0} m={0} variant="inverse" />
        <MenuItem onClick={() => deleteProject(project, onProjectDeleted)}>{tContextMenu("delete.label")}</MenuItem>
      </IconDropdown>

      <RenameProjectPanel
        isOpen={isRenameOpen}
        onClose={() => setIsRenameOpen(false)}
        currentName={displayName}
        onRename={handleRename}
      />

      <FileShareInputPanel
        isOpen={isFileShareInputOpen}
        onClose={() => setIsFileShareInputOpen(false)}
        fileShareUrl={project.fileShareUrl || ""}
        onAddFileShareUrl={handleAddFileShareUrl}
      />
    </>
  );
}
