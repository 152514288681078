import { useState, useEffect, useRef } from "react";
import { Input, Button, VStack, HStack, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { Modal } from "@design-system";
import { Project } from "@models/backend";
import { useTranslation } from "@hooks";

interface FileShareInputPanelProps {
  isOpen: boolean;
  onClose: () => void;
  fileShareUrl: string;
  onAddFileShareUrl: (fileShareUrl: string) => Promise<Project>;
}

function isValidLink(url: string): boolean {
  const dropboxRegex = /^https:\/\/(www\.)?dropbox\.com/;
  const googleDriveRegex = /^https:\/\/(drive|docs)\.google\.com/;
  return dropboxRegex.test(url) || googleDriveRegex.test(url);
}

export function FileShareInputPanel({ isOpen, onClose, fileShareUrl, onAddFileShareUrl }: FileShareInputPanelProps) {
  const [newFileShareUrl, setFileShareUrl] = useState(fileShareUrl);
  const [error, setError] = useState("");
  const initialFocusRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation("home.projects.fileSharePanel.attributes");

  useEffect(() => {
    if (isOpen) {
      setFileShareUrl(fileShareUrl);
      setError("");
    }
  }, [isOpen, fileShareUrl]);

  const handleSave = async () => {
    if (!isValidLink(newFileShareUrl)) {
      setError("This link is not compatible. Please enter a Dropbox or Google Drive link.");
      return;
    }
    setError("");
    await onAddFileShareUrl(newFileShareUrl);
    onClose();
  };

  const handleClose = () => {
    setFileShareUrl(fileShareUrl);
    setError("");
    onClose();
  };

  const modalHeader = fileShareUrl ? t("editHeader.label") : t("attachHeader.label");
  const primaryButtonText = fileShareUrl ? t("save.label") : t("attach.label");

  const isInputValid = newFileShareUrl.trim() === "" || isValidLink(newFileShareUrl);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} header={modalHeader} closable closeOnOverlayClick={false}>
      <VStack spacing={4} align="stretch">
        <FormControl isInvalid={!isInputValid && newFileShareUrl.trim() !== ""}>
          <Input
            ref={initialFocusRef}
            placeholder="https://"
            value={newFileShareUrl}
            onChange={(e) => {
              const value = e.target.value;
              setFileShareUrl(value);
              if (value.trim() === "" || isValidLink(value)) {
                setError("");
              } else {
                setError("This link is not compatible. Please enter a Dropbox or Google Drive link.");
              }
            }}
            autoFocus
          />
          {!isInputValid && newFileShareUrl.trim() !== "" && <FormErrorMessage>{error}</FormErrorMessage>}
        </FormControl>
        <HStack spacing={2} justify="flex-end">
          <Button variant="outline" onClick={handleClose}>
            {t("cancel.label")}
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            isDisabled={!newFileShareUrl.trim() || !isValidLink(newFileShareUrl)}
          >
            {primaryButtonText}
          </Button>
        </HStack>
      </VStack>
    </Modal>
  );
}
