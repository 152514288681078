import { PathCollectionAttributes } from "@models/backend";
import { Layer, PathCollection, PathCollectionInput } from "@models/project";
import { arrayToRecord, createPathCollection } from "@utils";
import { cloneDeep, defaultsDeep } from "lodash";
import { GetState, SetState } from "zustand";
import { PathAction, reducer as pathReducer } from "../reducers/pathReducer";
import { ModelsStore } from "../types";

export const pathCollectionsSlice = (set: SetState<ModelsStore>, get: GetState<ModelsStore>) => ({
  pathCollections: {},
  dispatchPathEdit: (modelId: string, collectionId: string, action: PathAction) => {
    let updatedCollection: PathCollection | undefined;
    set(({ pathCollections }) => {
      const { collections, newCollection } = pathCollections[modelId];
      const collection = collections[collectionId];
      updatedCollection = pathReducer(collection, action);
      return {
        pathCollections: {
          ...pathCollections,
          [modelId]: {
            newCollection,
            collections: {
              ...collections,
              [collectionId]: updatedCollection,
            },
          },
        },
      };
    });
    return updatedCollection!;
  },
  dispatchPathNew: (modelId: string, action: PathAction) => {
    let updatedCollection: PathCollectionInput;
    set(({ pathCollections }) => {
      const { collections, newCollection } = pathCollections[modelId];
      updatedCollection = pathReducer(newCollection as PathCollection, action);
      return {
        pathCollections: {
          ...pathCollections,
          [modelId]: {
            newCollection: updatedCollection,
            collections,
          },
        },
      };
    });
    return updatedCollection!;
  },
  clearNewPathCollection: async (modelId: string) => {
    const model = get().models.find(({ id }) => id === modelId);

    if (!model) {
      return;
    }

    return set(({ pathCollections }) => {
      const { collections } = pathCollections[modelId];

      return {
        pathCollections: {
          ...pathCollections,
          [modelId]: {
            collections: { ...collections },
            newCollection: createPathCollection(),
          },
        },
      };
    });
  },
  pushPathCollection: async (modelId: string, { id, ...layer }: Layer<PathCollectionAttributes>) => {
    const models = get().models;
    const model = models.find(({ id }) => id === modelId);
    if (!model) return;

    return set(({ pathCollections }) => {
      const { collections, newCollection } = pathCollections[modelId];

      collections[id] = {
        ...newCollection,
        ...layer,
        id,
      } as PathCollection;

      return {
        pathCollections: {
          ...pathCollections,
          [modelId]: {
            collections: collections,
            newCollection: createPathCollection(),
          },
        },
        selectedObjects: [{ type: "path", id, modelId: modelId }],
      };
    });
  },
  pushPathCollections: async (modelId: string, newPathCollections: PathCollection[]) => {
    const models = get().models;
    const model = models.find(({ id }) => id === modelId);
    if (!model) return;
    return set(({ pathCollections }) => {
      const { collections, newCollection } = pathCollections[modelId] ?? {
        collections: [],
        newCollection: createPathCollection(),
      };

      const result = arrayToRecord(newPathCollections, (collection) => collection.id!);

      return {
        pathCollections: {
          ...pathCollections,
          [modelId]: {
            collections: { ...collections, ...result },
            newCollection: newCollection,
          },
        },
        selectedObjects: [],
      };
    });
  },
  changePathCollection: (modelId: string, pathCollectionId: string, change: Partial<PathCollection>) => {
    const updatedCollections: PathCollection[] = [];
    set(({ pathCollections }) => {
      const { collections, newCollection } = pathCollections[modelId];
      const changedCollections = { ...collections };
      if ("usage" in change && change["usage"] === "guide") {
        for (const key in collections) {
          if (collections[key].usage === "guide") {
            changedCollections[key] = { ...changedCollections[key], usage: null } as PathCollection;
            updatedCollections.push(changedCollections[key]);
            break;
          }
        }
      }
      const mergedChange = { ...change };
      if ("attributes" in change) {
        const copy: PathCollection["attributes"] = cloneDeep(change["attributes"]) as PathCollection["attributes"];
        defaultsDeep(copy, changedCollections[pathCollectionId]["attributes"]);
        mergedChange["attributes"] = copy;
      }
      const collection = {
        ...collections[pathCollectionId],
        ...mergedChange,
      } as PathCollection;
      updatedCollections.push(collection);
      return {
        pathCollections: {
          ...pathCollections,
          [modelId]: {
            collections: {
              ...changedCollections,
              [pathCollectionId]: collection,
            },
            newCollection,
          },
        },
      };
    });
    return updatedCollections;
  },
  changePathCollectionNew: (modelId: string, change: Partial<PathCollectionInput>) => {
    const { newCollection: original, collections } = get().pathCollections[modelId];
    const newCollection = { ...original, ...change } as PathCollection;

    set(({ pathCollections }) => {
      return {
        pathCollections: {
          ...pathCollections,
          [modelId]: {
            collections,
            newCollection,
          },
        },
      };
    });

    return newCollection;
  },
  removePathCollection: (modelId: string, pathCollectionId: string) => {
    const models = get().models;
    const model = models.find(({ id }) => id === modelId);
    if (!model) return;
    return set(({ pathCollections }) => {
      const { collections, newCollection } = pathCollections[modelId];
      delete collections[pathCollectionId];
      return {
        pathCollections: {
          ...pathCollections,
          [modelId]: {
            collections: collections,
            newCollection: newCollection,
          },
        },
        selectedObjects: [],
      };
    });
  },
});
