import { colors } from "@design-system/colors.ts";
import { Curve } from "@fragments/project/workspace-3d/curve";
import { useCalibrationCurves } from "@hooks/project";
import { Project } from "@models/backend";
import { ColumnCurve, Model, SectionCurve } from "@models/project";
import { useModelsStore, useProjectState } from "@state";
import { useCallback, useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { workspace3dTokens } from "./workspace-3d-tokens.ts";

export type PartProps = {
  project: Project;
  model: Model;
};

function GridCurve({
  model: { id: modelId, mesh },
  curve: { id, segments },
  type,
  style: { color: defaultColor, opacity: defaultOpacity },
}: {
  model: Model;
  curve: ColumnCurve | SectionCurve;
  type: "section" | "column";
  style: { color: string; opacity: number };
}) {
  const { selectedObjects, hoveredObject, setSelectedObjects } = useModelsStore();
  const { renderModeSelection } = useProjectState();
  const selected = !!selectedObjects.find((o) => o.id === id);
  const hovered = hoveredObject?.id === id;

  const color = selected || hovered ? colors.blue["600"] : defaultColor;
  const opacity = selected || hovered ? 1.0 : defaultOpacity;
  const lineWidth = hovered ? 2.0 : 1.0;

  return segments.map(({ vertices }, idx) => (
    <Curve
      key={id + "," + idx}
      mesh={mesh}
      curve={vertices}
      color={color}
      opacity={opacity}
      width={renderModeSelection === "knitmesh" ? lineWidth * 3 : lineWidth}
      showLengthOverride
      isSelected={selected}
      onSelected={() => setSelectedObjects([{ type, id, modelId }])}
      isHovered={hovered}
      userData={{ type, id, modelId }}
    />
  ));
}

export function Part({ project, model }: PartProps) {
  const { columns, sections, selectedObjects } = useModelsStore();
  const guideSource = useModelsStore(({ pathCollections }) =>
    Object.values(pathCollections[model.id]?.collections)?.find(({ usage }) => usage === "guide"),
  );
  const { removeColumnCurve, removeSectionCurve, setColumnCurves, setSectionCurves } = useCalibrationCurves({
    project,
    model,
  });
  const sectionCurves = sections[model.id]?.sectionCurves;
  const sectionCurveStyle = workspace3dTokens.path.section;
  const columnCurves = columns[model.id]?.columnCurves;
  const columnCurveStyle = workspace3dTokens.path.column;

  useHotkeys(["delete", "backspace"], async () => {
    const selectedCurves = selectedObjects.filter((obj) => obj.type === "section" || obj.type === "column");

    await Promise.all(
      selectedCurves.map((obj) => {
        if (obj.type === "section") {
          removeSectionCurve(obj.id);
        } else if (obj.type === "column") {
          removeColumnCurve(obj.id);
        }
      }),
    );
  });

  const cleanUp = useCallback(async () => {
    if (columnCurves?.length) {
      await setColumnCurves([]);
    }
    if (sectionCurves?.length) {
      await setSectionCurves([]);
    }
  }, [columnCurves?.length, sectionCurves?.length]);

  useEffect(() => {
    if (!guideSource) {
      cleanUp().catch(console.warn);
    }
  }, [cleanUp, guideSource]);

  return (
    <>
      {sectionCurves?.map((curve) => (
        <GridCurve key={curve.id} model={model} type="section" curve={curve} style={sectionCurveStyle} />
      ))}
      {columnCurves?.map((curve) => (
        <GridCurve key={curve.id} model={model} type="column" curve={curve} style={columnCurveStyle} />
      ))}
    </>
  );
}
