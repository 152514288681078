import { Project3DToolbarState } from "@state";

export function cursor(selectedTool: Project3DToolbarState["_3DToolbarSelection"]) {
  switch (selectedTool) {
    case "pen":
    case "column":
    case "section":
      return "pen";
    case "comment":
      return "comment";
    default:
      return "select";
  }
}
