import { Box, Spacer } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { IconButton, Separator, spacing, Toolbar } from "@design-system";
import { ApplicationContextMenu } from "@fragments";
import { useModelExporter, useTranslation } from "@hooks";
import { useHotkeys } from "react-hotkeys-hook";
import { ExportIcon } from "./icons";
import { Tools3DToolbar } from "./Tools3DToolbar";
import { useCurrentProject } from "@fragments/project/container/useCurrentProject";
import { ActiveUsers } from "@design-system/components/ActiveUsers/ActiveUsers";
import { useAuth } from "@auth";
import { useProjectPresence } from "@hooks/project/useProjectPresence";
import { RenderModeDropdown } from "./RenderModeDropdown";

function ExportButton(props: StyleProps) {
  const { t } = useTranslation("bottomToolbar.actions");
  const { exportModel, isExportModelDisabled } = useModelExporter({ refresh: false });

  useHotkeys(
    "ctrl+shift+e",
    () => {
      if (isExportModelDisabled) {
        return;
      }
      exportModel(true);
    },
    { preventDefault: true },
  );

  return (
    <IconButton
      onClick={() => exportModel()}
      label={t("export.label")}
      leftIcon={<ExportIcon width="1rem" />}
      shortcut={{ keys: "ctrl+e" }}
      size="xs"
      variant="primary"
      isDisabled={isExportModelDisabled}
      disabledReason={t("export.disabled")}
      tooltipPlacement={"start"}
      {...props}
      data-test-id="export-bitmap-button"
    />
  );
}

export function TopToolbar(props: StyleProps) {
  const { user } = useAuth();
  const { project } = useCurrentProject();
  const projectId = project?.id;
  const { presenceUsers: activeUsersInProject } = useProjectPresence(user, projectId);

  return (
    <Toolbar size="full" direction="row" px="1rem" justifyContent="space-between" {...props}>
      <Box ml={spacing.space[100]}>
        <ApplicationContextMenu
          showThemeButtons={false}
          showDashboardLink={true}
          showContactUs={true}
          fileShareUrl={project?.fileShareUrl}
        />
      </Box>
      <Spacer />
      <Tools3DToolbar />
      <Separator height={spacing.space["300"]} margin={spacing.space["200"]} />
      <RenderModeDropdown />
      <Spacer />
      <Box display="flex" alignItems="center" gap={spacing.space[400]}>
        <ActiveUsers users={activeUsersInProject} maxDisplayed={3} />
        <ExportButton height={spacing.space[400]} />
      </Box>
    </Toolbar>
  );
}
