import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";
import { commentThreadsState } from "./slices";
import { ProjectState } from "./types";

export const useProjectState = create(
  subscribeWithSelector<ProjectState>((set, get) => ({
    _3DToolbarSelection: "select",
    renderModeSelection: "design",
    showMeasurements: false,
    showDirectionality: false,

    set3DToolbarSelection: (_3DToolbarSelection) => set(() => ({ _3DToolbarSelection })),
    setRenderModeSelection: (renderModeSelection) => set(() => ({ renderModeSelection })),
    setShowMeasurements: (showMeasurements: boolean) => set(() => ({ showMeasurements })),
    setShowDirectionality: (showDirectionality: boolean) => set(() => ({ showDirectionality })),

    reset: () => {
      get().clearCommentThreads();

      set(() => {
        return {
          _3DToolbarSelection: "none",
          renderModeSelection: "design",
          showMeasurements: false,
          showDirectionality: false,
        };
      });
    },

    ...commentThreadsState(set, get),
  })),
);

export * from "./types";
