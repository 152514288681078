import { ReactNode, useEffect } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Flex,
  Heading,
  HStack,
  Spacer,
} from "@chakra-ui/react";
import { DropBox, useSemanticTokens, spacing } from "@design-system";
import { useDrop, DropTargetMonitor } from "react-dnd";
import { LayerValue } from "./Common";

type SidebarGroupProps = {
  name: string;
  canDrop?: (item: LayerValue) => boolean;
  drop?: (item: LayerValue) => void;
  replace: boolean;
  children?: ReactNode;
  group: "guide" | "zone" | "unused" | "section" | "column" | "reference" | "goring";
};

export function SidebarGroup({
  name,
  children,
  canDrop: canDropFunction,
  drop: dropFunction,
  replace,
  group,
}: SidebarGroupProps) {
  const [{ isOver, canDrop, item: itemUnknown }, drop] = useDrop(
    () => ({
      accept: "path",
      canDrop: canDropFunction,
      drop: dropFunction,
      collect: (monitor: DropTargetMonitor) => ({
        item: monitor.getItem(),
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [canDropFunction, dropFunction],
  );
  const semanticTokens = useSemanticTokens();
  const iconPrimary = semanticTokens.icons.primary;
  const textClassicSecondary = semanticTokens.text.classic.secondary;

  const item = itemUnknown as LayerValue;
  const dropBox = <DropBox variant={canDrop ? "ok" : "error"} />;
  const content =
    canDropFunction && isOver && item.layerData.group !== group ? (
      replace ? (
        dropBox
      ) : (
        <>
          {dropBox}
          {children}
        </>
      )
    ) : (
      children
    );

  useEffect(() => {
    if (canDropFunction && isOver && !canDrop) {
      document.body.style.cursor = "not-allowed";
      return () => {
        document.body.style.cursor = "";
      };
    }
  }, [canDropFunction, isOver, canDrop]);

  return (
    <Accordion ref={drop} allowMultiple defaultIndex={[0]} w="full">
      <AccordionItem w="full">
        <Container alignItems="center" gap={spacing.space[50]} variant="sidebarLayer" height="1.5rem">
          <Flex alignItems="center" height="100%">
            <Spacer mx={spacing.space[200]} />
            {children ? (
              <AccordionButton
                onClick={(e) => {
                  e.stopPropagation();
                }}
                userSelect="none"
              >
                <AccordionIcon color={iconPrimary} />
              </AccordionButton>
            ) : (
              <Spacer mx={spacing.space[200]} />
            )}
          </Flex>
          <Heading as="h5" color={textClassicSecondary} size="h5">
            {name}
          </Heading>
        </Container>
        {children && (
          <HStack role="group" gap={0}>
            <Box w="full">
              <AccordionPanel w="full" rowGap={0} marginTop={0}>
                {content}
              </AccordionPanel>
            </Box>
          </HStack>
        )}
      </AccordionItem>
    </Accordion>
  );
}
