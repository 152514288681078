import { ToggleIconButton, ToggleIconDropdown, Toolbar } from "@design-system";
import { DesignViewIcon, KnitMeshViewIcon } from "./icons";
import { ReactElement, useState } from "react";
import { useTranslation } from "@hooks";
import { useProjectState, Project3DToolbarState } from "@state/project";
import { useModelsStore } from "@state";

export function RenderMode({
  name,
  keys,
  icon,
  disabled,
  setIsOpen,
}: {
  name: Project3DToolbarState["renderModeSelection"];
  keys: string;
  icon: ReactElement;
  disabled?: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) {
  const { t } = useTranslation("project3D.toolbarLeft.renderModes");
  const { renderModeSelection, setRenderModeSelection } = useProjectState();

  return (
    <ToggleIconButton
      key={name}
      disabled={disabled}
      isSelected={renderModeSelection === name}
      onToggle={() => {
        setRenderModeSelection(name);
        setIsOpen(false);
      }}
      label={t(name + ".label")}
      icon={icon}
      shortcut={{
        label: t(name + ".label"), // will need to display readable modifier shortcut keys
        keys: keys,
      }}
      placement={"left"}
      variant="inverse"
    />
  );
}

export function RenderModeDropdown() {
  const { t } = useTranslation("project3D.toolbarLeft.renderModes");
  const { renderModeSelection } = useProjectState();
  const { process } = useModelsStore(({ process }) => ({ process }));
  const [isOpen, setIsOpen] = useState(false);

  const icons: Record<Project3DToolbarState["renderModeSelection"], JSX.Element> = {
    design: <DesignViewIcon />,
    knitmesh: <KnitMeshViewIcon />,
  };

  return (
    <ToggleIconDropdown
      isSelected={isOpen}
      isOpen={isOpen}
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={() => {
        setIsOpen(false);
      }}
      onToggle={() => {
        setIsOpen(!isOpen);
      }}
      icon={icons[renderModeSelection]}
      label={t(renderModeSelection + ".label")}
      shortcut={{
        label: t(renderModeSelection + ".label"), // will need to display readable modifier shortcut keys
        keys: "`",
      }}
      variant="inverse"
      placement={"bottom"}
    >
      <Toolbar direction={"column"}>
        <RenderMode name="design" keys="1" icon={<DesignViewIcon />} setIsOpen={setIsOpen} />
        <RenderMode name="knitmesh" keys="2" icon={<KnitMeshViewIcon />} setIsOpen={setIsOpen} disabled={!process} />
      </Toolbar>
    </ToggleIconDropdown>
  );
}
