import { modelAttributes as defaultAttributes } from "@defaults";
import { Model } from "@models/project";
import { arrayToRecord, createPathCollection } from "@utils";
import { defaultsDeep } from "lodash";
import { StoreApi } from "zustand";
import { ModelsStore, PathCollections } from "../types";

export const modelsSlice = (set: StoreApi<ModelsStore>["setState"]) => ({
  models: [],
  addModel: async (model: Model) => {
    const modelWithAttributes = {
      ...model,
      attributes: defaultsDeep(model.attributes, defaultAttributes),
    };
    const newCollection = createPathCollection();
    set(({ models, pathCollections, sections, columns }: ModelsStore) => ({
      models: [...models, modelWithAttributes],
      pathCollections: {
        ...pathCollections,
        [model.id]: {
          newCollection,
          collections: {},
        },
      },
      sections: {
        ...sections,
        [model.id]: {
          sectionCurves: [],
        },
      },
      columns: {
        ...columns,
        [model.id]: {
          columnCurves: [],
        },
      },
      selectedObjects: [{ type: "model", id: model.id }],
    }));
  },
  addReferenceModel: (parentModelId: string, referenceModel: Model) => {
    set((state) => {
      const models = state.models.map((model) => {
        if (model.id === parentModelId) {
          const references = model.references ? [...model.references, referenceModel] : [referenceModel];
          return { ...model, references };
        } else {
          return model;
        }
      });

      return { models: [...models, referenceModel] };
    });
  },
  setModels: async (models: Model[]) => {
    const modelsWithAttributes = models.map(({ attributes, ...rest }) => ({
      ...rest,
      attributes: defaultsDeep(attributes, defaultAttributes),
    }));
    set(() => {
      const pathCollections: PathCollections = arrayToRecord(
        models,
        (model) => model.id,
        () => ({
          collections: {},
          newCollection: createPathCollection(),
        }),
      );
      return { models: modelsWithAttributes, pathCollections };
    });
  },
  updateModel: (id: string, update: Partial<Model>) =>
    set(({ models }: ModelsStore) => {
      const index = models.findIndex((model) => model.id === id);

      if (index === -1) {
        throw new Error(`updateModel: Model ${id} not found`);
      }

      const result = [...models];
      const model = { ...models[index], ...update };
      result[index] = model;

      if (model?.parentId) {
        const parentIndex = models.findIndex(({ id }) => id === model.parentId);

        if (parentIndex !== -1) {
          const parent = result[parentIndex];
          console.log("updating parent");
          result[parentIndex] = {
            ...parent,
            references: parent.references.map((reference) => (reference.id === id ? model : reference)),
          };
        }
      }

      return { models: result };
    }),
  removeReferenceModel: async (modelId: string) => {
    set(({ models }: ModelsStore) => {
      const model = models.find(({ id }) => id === modelId);

      if (!model?.parentId) {
        return { models };
      }

      const parent = models.find(({ id }) => id === model.parentId);

      if (parent) {
        parent.references = parent.references.filter(({ id }) => id !== modelId);
      }

      return { models: models.filter(({ id }) => id !== modelId) };
    });
  },
});
