import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

export function CommentIcon(props: IconProps) {
  return (
    <Icon fill="none" viewBox="0 0 20 20" height="5" width="5" {...props}>
      <path
        d="M10.0001 17.7083C14.2573 17.7083 17.7084 14.2572 17.7084 9.99996C17.7084 5.74276 14.2573 2.29163 10.0001 2.29163C5.74289 2.29163 2.29175 5.74276 2.29175 9.99996C2.29175 11.2741 2.60088 12.476 3.14823 13.5348L2.29175 17.7083L6.57416 16.907C7.60618 17.4199 8.76945 17.7083 10.0001 17.7083Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
    </Icon>
  );
}
