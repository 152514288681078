import { PathCollectionAttributes, ModelAttributes } from "@models/backend";
import { RequiredChilds } from "@utils";

type DefaultModelAttributes = RequiredChilds<
  Required<Pick<ModelAttributes, "stitchDensity" | "sections" | "columns" | "bitmapSettings">>
>;

export const modelAttributes: DefaultModelAttributes = {
  stitchDensity: {
    course: 12.0,
    wale: 8.0,
  },
  sections: {
    count: 10,
  },
  columns: {
    count: 10,
  },
  bitmapSettings: {
    flip: false,
    includeCalibrationGrid: true,
  },
};

type DefaultPathCollectionAttributes = Omit<PathCollectionAttributes, "transition"> & {
  transition: Required<Required<PathCollectionAttributes>["transition"]>;
};

export const pathCollectionAttributes: DefaultPathCollectionAttributes = {
  stitchDensity: {
    course: 12.0,
    wale: 8.0,
  },
  transition: {
    boundary: 0,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
};
