import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

export function CloseIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" height="16px" width="16px" fill="none" {...props}>
      <path
        d="M3.83325 3.83325L12.1666 12.1666M12.1666 3.83325L3.83325 12.1666"
        stroke="currentColor"
        strokeLinecap="square"
      />
    </Icon>
  );
}
