import { arrayToRecord, recordToArray } from "@utils";
import { StoreApi } from "zustand";
import { CommentThreadState, ProjectCommentThreadsState } from "../types";

export const commentThreadsState = (
  set: StoreApi<ProjectCommentThreadsState>["setState"],
  get: StoreApi<ProjectCommentThreadsState>["getState"],
): ProjectCommentThreadsState => ({
  commentThreads: {},

  addCommentThreads: (states: CommentThreadState[]) => {
    set(({ commentThreads }) => ({
      commentThreads: {
        ...commentThreads,
        ...arrayToRecord(states, (state) => state.id),
      },
    }));
  },
  getCommentThreads: () => recordToArray(get().commentThreads),
  getCommentThread: (id: CommentThreadState["id"]) => get().commentThreads[id],
  setCommentThread: (state: CommentThreadState) => {
    if (state === get().commentThreads[state.id]) {
      return;
    }

    set(({ commentThreads }) => ({
      commentThreads: {
        ...commentThreads,
        [state.id]: state,
      },
    }));
  },
  removeCommentThread: (id: CommentThreadState["id"]) => {
    const thread = get().getCommentThread(id);

    set(({ commentThreads }) => {
      delete commentThreads[id];

      return {
        commentThreads: { ...commentThreads },
      };
    });

    return thread;
  },
  clearCommentThreads: () => set(() => ({ commentThreads: {} })),
});
