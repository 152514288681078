import { useCustomCursor, usePointerState } from "@hooks";
import { useSelect } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { useModelsStore, useProjectState } from "@state";
import { computeSelectedObjects, cursor, pointerInDeadZone } from "@utils";
import { isEqual } from "lodash";
import { ReactNode, useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";

export type SelectToolProps = { children: ReactNode };

export function SelectTool({ children }: SelectToolProps) {
  const { setCursor } = useCustomCursor();
  const { altKey, metaKey, ctrlKey, pointer2Down } = usePointerState();
  const { _3DToolbarSelection } = useProjectState();
  const { selectedObjects, setSelectedObjects } = useModelsStore();
  const selection = useSelect();
  const { pointer, size } = useThree();

  useHotkeys("esc", () => setSelectedObjects([]));

  useEffect(() => {
    if (_3DToolbarSelection === "select") {
      if (altKey) {
        setCursor("selectMinus");
      } else if (metaKey || ctrlKey) {
        setCursor("selectBending");
      } else {
        setCursor("select");
      }
    } else {
      setCursor(cursor(_3DToolbarSelection));
    }
  }, [altKey, ctrlKey, metaKey, setCursor]);

  useEffect(() => {
    if (pointerInDeadZone(pointer, size)) {
      return;
    }

    const newlySelectedObjects = computeSelectedObjects(selection);

    // !pointer2down keeps from deselection on orbit pan etc. on macOs
    if (_3DToolbarSelection === "select" && !pointer2Down && !isEqual(newlySelectedObjects, selectedObjects)) {
      setSelectedObjects(newlySelectedObjects);
    }
  }, [selection]);

  return children;
}
