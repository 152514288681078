import "@/globals";
import { ColumnCurve, Model, PathCollection, SectionCurve } from "@models/project";
import { computeZonesFromPathCollections } from "@utils";
import { getV3dApi } from "@utils/project/initV3dApi.ts";
import { Curve, GridCurve, GridCurves, PathInput, Vector3 } from "@variant-tech/pattern-derivation";
import { pick } from "lodash";

export async function generateGridCurves(
  model: Model,
  guideCurve: PathInput,
  pathCollections: PathCollection[],
  columnsCount: number,
  sectionsCount: number,
): Promise<GridCurves> {
  return await getV3dApi().generateGridCurves(model.mesh3DBase, {
    guideCurve,
    zones: computeZonesFromPathCollections(pathCollections, model),
    columnsCount,
    sectionsCount,
  });
}

export async function computeSectionCurves(
  model: Model,
  guideCurve: PathInput,
  pathCollections: PathCollection[],
  anchorPoints: Vector3[],
): Promise<Curve[]> {
  return await getV3dApi().computeSectionCurves(model.mesh3DBase, {
    guideCurve,
    anchorPoints,
    zones: computeZonesFromPathCollections(pathCollections, model),
  });
}

export async function computeColumnCurves(
  model: Model,
  guideCurve: PathInput,
  pathCollections: PathCollection[],
  anchorPoints: Vector3[],
): Promise<Curve[]> {
  return await getV3dApi().computeColumnCurves(model.mesh3DBase, {
    guideCurve,
    anchorPoints,
    zones: computeZonesFromPathCollections(pathCollections, model),
  });
}

export type ColumnOrSectionCurve = ColumnCurve | SectionCurve;

export function getCurvesFromGridCurves(
  curves: ColumnOrSectionCurve[],
  gridCurves: GridCurve[],
): ColumnOrSectionCurve[] {
  return gridCurves.map((gc, i) => ({
    ...pick(curves[i], ["id", "name", "attributes"]),
    ...pick(gc, ["points", "segments"]),
  }));
}
