import { useAuth } from "@auth";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Input,
  MenuItem,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { IconDropdown, Modal, spacing, useSemanticTokens } from "@design-system";
import { useCurrentProject } from "@fragments/project/container/useCurrentProject.ts";
import { MoreIcon } from "@fragments/projects/view/icons";
import { useTranslation } from "@hooks";
import { ProjectComment } from "@models/backend";
import moment from "moment";
import { useRef, useState } from "react";

export function ThreadComment({
  comment,
  onEdit,
  onDelete,
}: {
  comment: ProjectComment;
  onEdit: (commentId: ProjectComment["id"], content: ProjectComment["content"]) => void;
  onDelete: (id: ProjectComment["id"]) => void;
}) {
  const contextMenuDisclosureProps = useDisclosure();
  const deleteModalDisclosureProps = useDisclosure();
  const semanticTokens = useSemanticTokens();
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const commentInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation("project.comments.comment");
  const { user } = useAuth();
  const { project } = useCurrentProject();
  const isEditEnabled =
    user.organizations.find((organization) => organization.organizationId === project.organizationId)?.memberRole ===
      "admin" || comment.author.id === user.id;

  return (
    <>
      <VStack alignItems="start" gap={spacing.space["100"]} w="full" p={0}>
        <HStack justifyContent="space-between" w="full" role="group">
          <HStack gap={spacing.space["200"]}>
            <Avatar
              name={comment.author.name}
              src={comment.author.avatar}
              width={spacing.space[600]}
              height={spacing.space[600]}
              fontSize="xs"
              boxSize="2rem"
            />
            <VStack gap={0} alignItems="start">
              <Text variant="3xs-medium" color={semanticTokens.text.invert.tertiary}>
                {moment(comment.createdAt).fromNow()}
              </Text>
              <Text variant="2xs-medium" color={semanticTokens.text.classic.primary}>
                {comment.author.name}
              </Text>
            </VStack>
          </HStack>
          <Box
            _groupHover={{ opacity: 1 }}
            opacity={contextMenuDisclosureProps.isOpen ? 1 : 0}
            transition="opacity 0.2s"
          >
            {isEditEnabled && (
              <IconDropdown
                label="more"
                icon={<MoreIcon boxSize={spacing.space[400]} />}
                variant="classic-context-menu"
                buttonStyleProps={{
                  minWidth: spacing.space[800],
                  boxSize: spacing.space[800],
                }}
                {...contextMenuDisclosureProps}
              >
                <MenuItem onClick={() => setEditMode(true)}>{t("actions.edit.label")}</MenuItem>
                <MenuItem onClick={() => deleteModalDisclosureProps.onOpen()}>{t("actions.delete.label")}</MenuItem>
              </IconDropdown>
            )}
          </Box>
        </HStack>
        {isEditMode ? (
          <Box>
            <Input
              bg="none"
              height="32px"
              placeholder={comment.content}
              defaultValue={comment.content}
              onKeyUp={async ({ key }) => {
                if (key === "Enter") {
                  onEdit(comment.id, commentInputRef.current?.value ?? "");
                  setEditMode(false);
                }
              }}
              autoFocus={true}
              ref={commentInputRef}
            />
            <ButtonGroup as={Flex} justifyContent="flex-end">
              <Button variant="classic-transparent" onClick={() => setEditMode(false)}>
                {t("actions.edit.confirmation.actions.cancel")}
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  onEdit(comment.id, commentInputRef.current?.value ?? "");
                  setEditMode(false);
                }}
              >
                {t("actions.edit.confirmation.actions.edit")}
              </Button>
            </ButtonGroup>
          </Box>
        ) : (
          <Text variant="xs-regular" color={semanticTokens.text.classic.primary}>
            {comment.content}
          </Text>
        )}
      </VStack>
      <Modal header={t("actions.delete.confirmation.header")} {...deleteModalDisclosureProps}>
        <Text color={semanticTokens.text.classic.secondary}>{t("actions.delete.confirmation.content")}</Text>
        <ButtonGroup as={Flex} justifyContent="flex-end">
          <Button variant="classic-outline" onClick={deleteModalDisclosureProps.onClose}>
            {t("actions.delete.confirmation.actions.cancel")}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              onDelete(comment.id);
              deleteModalDisclosureProps.onClose();
            }}
          >
            {t("actions.delete.confirmation.actions.delete")}
          </Button>
        </ButtonGroup>
      </Modal>
    </>
  );
}
