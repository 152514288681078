import { colors } from "@design-system/colors";
import { DoubleSide, MultiplyBlending } from "three";

const path = {
  lineWidth: {
    defaultWidth: 1.0,
    hoveredWidth: 2.0,
  },
  guide: {
    opacity: 1.0,
    color: colors.green["600"],
  },
  zone: {
    opacity: 0.4,
    color: colors.gray["650"],
  },
  goring: {
    opacity: 0.4,
    color: colors.gray["650"],
  },
  unused: {
    opacity: 0.15,
    color: colors.gray["650"],
  },
  section: {
    opacity: 0.4,
    color: colors.orange["600"],
  },
  column: {
    opacity: 0.4,
    color: colors.green["600"],
  },
};

const surfacePoint = {
  innerRadius: 4,
  outerRadius: 6,
};

const bezierHandle = {
  innerRadius: 3,
  outerRadius: 5,
};

const clickTarget = {
  tolerance: 2,
};

const penTool = {
  minDistance: surfacePoint.outerRadius + clickTarget.tolerance,
};

const material = {
  clearcoat: 0.2,
  clearcoatRoughness: 0.6,
  metalness: 0.3,
  roughness: 0.5,
  side: DoubleSide,
  specularIntensity: 1.0,
  specularColor: colors.white,
};

const knitMesh = {
  material: {
    vertexColors: true,
    side: DoubleSide,
    polygonOffset: true,
    polygonOffsetUnits: 1,
    polygonOffsetFactor: 2,
  },
  quadMaterial: {
    color: colors.gray["100"],
    side: DoubleSide,
    // transparent: true,
    // opacity: 0.25,
    // vertexColors: true,
    blending: MultiplyBlending,
    wireframe: true,
  },
};

const lighting = {
  zOffset: 10000,
  shoulder: {
    intensity: 1.2,
    color: colors.gray["100"],
    xOffset: 15.5,
    yOffset: 5,
  },
  ground: {
    intensity: 0.4,
    color: colors["warm-white"],
    xOffset: 0,
    yOffset: -25.5,
  },
  hemisphere: {
    intensity: 1.25,
  },
};

export const workspace3dTokens = {
  path,
  surfacePoint,
  bezierHandle,
  clickTarget,
  penTool,
  material,
  lighting,
  knitMesh,
};
