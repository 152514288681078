import { IconProps } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";

export function CommentPlacementIcon({ fill = "currentColor", stroke = "currentColor", ...props }: IconProps) {
  return (
    <Icon viewBox="0 0 40 40" width="40px" height="40px" {...props}>
      <path
        d="M0.5 39V39.5H1H21C31.2173 39.5 39.5 31.2173 39.5 21V19C39.5 8.78273 31.2173 0.5 21 0.5H19C8.78273 0.5 0.5 8.78273 0.5 19V39Z"
        fill={fill as string}
        stroke={stroke as string}
        strokeLinecap="square"
      />
    </Icon>
  );
}
