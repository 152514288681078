import { Box, Menu, MenuButton, MenuList, UseDisclosureProps } from "@chakra-ui/react";
import { ToggleIconButton, ToggleIconButtonProps, useSemanticTokens } from "@design-system";
import { DropdownProps } from "./Dropdown.tsx";

type ToggleIconDropdownProps = ToggleIconButtonProps & DropdownProps & UseDisclosureProps;

export function ToggleIconDropdown({
  isSelected,
  isOpen,
  defaultIsOpen,
  onOpen,
  onClose,
  onToggle,
  icon,
  label,
  placement = "right",
  variant,
  children,
  shortcut,
}: ToggleIconDropdownProps) {
  const semanticTokens = useSemanticTokens();

  return (
    <Box
      sx={{
        position: "relative",
        color: isSelected ? semanticTokens.border.accent.primary : semanticTokens.border.border,
        "::after": {
          content: '""',
          position: "absolute",
          bottom: "4px",
          right: "4px",
          width: "0",
          height: "0",
          borderLeft: "4px solid transparent",
          borderBottom: `4px solid currentColor`,
        },
      }}
    >
      {isSelected ? (
        <Menu
          placement={placement}
          isOpen={isOpen}
          defaultIsOpen={defaultIsOpen}
          onOpen={onOpen}
          onClose={onClose}
          variant={variant}
        >
          <MenuButton>
            <ToggleIconButton
              icon={icon}
              label={label}
              shortcut={shortcut}
              isSelected={isSelected}
              tooltipDisabled={isOpen}
              onToggle={onToggle}
              placement={placement}
              variant={variant}
            />
          </MenuButton>
          <MenuList minWidth={"fit-content"} margin="0">
            {children}
          </MenuList>
        </Menu>
      ) : (
        <ToggleIconButton
          icon={icon}
          label={label}
          shortcut={shortcut}
          isSelected={isSelected}
          onToggle={onToggle}
          placement={placement}
          variant={variant}
        />
      )}
    </Box>
  );
}
