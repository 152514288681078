import { Line } from "@react-three/drei";
import { OnSelectedParams } from "@utils";
import { Vector3 } from "three";

const OCCLUDED_OPACITY = 0.25;

const CLICKABLE_AREA_TOLERANCE = 10;

export function CurveSegment({
  occluded,
  vertices,
  color,
  opacity,
  width = 1,
  isHovered,
  onHovered,
  isSelected,
  onSelected,
  userData = {},
}: {
  occluded: boolean;
  vertices: Vector3[];
  color?: string;
  opacity?: number;
  width?: number;
  isHovered?: boolean;
  onHovered?: (hovered: boolean) => void;
  isSelected?: boolean;
  onSelected?: (keys: OnSelectedParams) => void;
  userData?: Record<string, unknown>;
}) {
  if (!vertices.length) {
    return undefined;
  }

  if (occluded && !isHovered && !isSelected) {
    return undefined;
  }

  return (
    <>
      <Line
        points={vertices}
        lineWidth={width + CLICKABLE_AREA_TOLERANCE}
        opacity={0}
        transparent
        onPointerEnter={() => onHovered?.(true)}
        onPointerLeave={() => onHovered?.(false)}
        onClick={(e) => onSelected?.(e)}
        userData={{ ...userData, occluded }}
      />
      <Line
        points={vertices}
        color={color}
        lineWidth={width}
        depthTest={false}
        transparent
        opacity={occluded ? OCCLUDED_OPACITY : opacity}
      />
    </>
  );
}
