import { ReactElement } from "react";
import { Box, HStack, Text } from "@chakra-ui/react";
import { spacing, useSemanticTokens } from "@design-system";
import { KnitStructure, ColorEntry } from "@models/backend";

export type LayerData = {
  id: string;
  group: string;
  isLoop?: boolean;
  zoneIndex?: number;
};

export type LayerValue = { type: "model" | "path"; layerData: LayerData };

export type PathInfo = {
  key: string;
  name: string;
  icon: ReactElement;
  layerId: string;
  layerType: "path";
  group: "guide" | "zone" | "unused" | "goring";
  isLoop: boolean;
  selected: boolean;
  hovered: boolean;
  onSelect: () => void;
  onHover: () => void;
  onUnhover: () => void;
  isEditable: boolean;
};

export type SectionInfo = {
  key: string;
  name: string;
  icon: ReactElement;
  layerId: string;
  layerType: "section";
  group: "section";
  selected: boolean;
  hovered: boolean;
  onSelect: () => void;
  onHover: () => void;
  onUnhover: () => void;
};

export type ColumnInfo = {
  key: string;
  name: string;
  icon: ReactElement;
  layerId: string;
  layerType: "column";
  group: "column";
  selected: boolean;
  hovered: boolean;
  onSelect: () => void;
  onHover: () => void;
  onUnhover: () => void;
};

export const StructureLabel = ({ structure }: { structure?: KnitStructure }) => {
  const semanticTokens = useSemanticTokens();

  if (!structure) {
    return null;
  }
  return (
    <HStack spacing={spacing.space["200"]} alignItems="center" width="100%">
      <Box width="16px" height="16px" flexShrink={0} bg={structure?.color ?? undefined} />
      <Box flex="1" minWidth="0">
        <Text color={semanticTokens.text.primary} variant="2xs-regular" noOfLines={1}>
          {structure?.name ?? ""}
        </Text>
      </Box>
    </HStack>
  );
};

export const ColorEntryLabel = ({ colorEntry }: { colorEntry: ColorEntry }) => {
  const semanticTokens = useSemanticTokens();

  if (!colorEntry) {
    return null;
  }
  return (
    <HStack spacing={spacing.space["400"]} alignItems="center">
      <Box width="16px" height="16px" bg={colorEntry?.color ?? undefined} />
      <Text color={semanticTokens.text.primary} variant="2xs-regular" whiteSpace="nowrap">
        {colorEntry?.code ?? ""}
      </Text>
    </HStack>
  );
};

export const PaletteColorEntryLabel = ({ colorEntry }: { colorEntry: ColorEntry }) => {
  const semanticTokens = useSemanticTokens();

  if (!colorEntry) {
    return null;
  }
  return (
    <>
      <Box
        float="left"
        width="16px"
        height="16px"
        marginRight={spacing.space["100"]}
        bg={colorEntry?.color ?? undefined}
      />
      <Text color={semanticTokens.text.classic.primary} variant="2xs-regular" whiteSpace="nowrap">
        {colorEntry?.code ?? ""}
      </Text>
    </>
  );
};

export default PaletteColorEntryLabel;
