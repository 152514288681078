import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

export function SendIcon({ fill = "currentColor", ...props }: IconProps) {
  return (
    <Icon viewBox="0 0 20 20" height="20px" width="20px" {...props}>
      <path
        d="M3.93268 9.16675L2.08545 1.77783L18.5301 10.0001L2.08545 18.2224L3.93271 10.8334H8.33335V9.16675H3.93268Z"
        fill={fill as string}
      />
    </Icon>
  );
}
