import { MathUtils, Vector3 } from "three";

function closestPointOnSegment(A: Vector3, B: Vector3, input: Vector3): Vector3 {
  // Vector from A to B
  const AB = B.clone().sub(A);
  // Vector from A to P
  const AP = input.clone().sub(A);

  // Project AP onto AB, but clamp t to [0, 1]
  const t = MathUtils.clamp(AP.dot(AB) / AB.lengthSq(), 0, 1);

  // Compute the closest point X on the segment
  return A.clone().add(AB.multiplyScalar(t));
}

export function findClosestPointOnLine(points: Vector3[], input: Vector3): Vector3 | null {
  if (points.length < 2) {
    throw new Error("The line must have at least two points.");
  }

  let closestPoint: Vector3 | null = null;
  let minDistanceSq = Infinity;

  for (let i = 0; i < points.length - 1; i++) {
    const A = points[i];
    const B = points[i + 1];

    // Find the closest point on the current segment
    const candidatePoint = closestPointOnSegment(A, B, input);

    // Compute squared distance to avoid unnecessary sqrt calculations
    const distanceSq = candidatePoint.distanceToSquared(input);

    // Update if this point is closer
    if (distanceSq < minDistanceSq) {
      minDistanceSq = distanceSq;
      closestPoint = candidatePoint;
    }
  }

  return closestPoint;
}

export function flatArrayToVector3Array(data: Float32Array): Vector3[] {
  if (data.length % 3 !== 0) {
    throw new Error("Input array length must be a multiple of 3.");
  }

  const vectors: Vector3[] = [];
  for (let i = 0; i < data.length; i += 3) {
    vectors.push(new Vector3(data[i], data[i + 1], data[i + 2]));
  }

  return vectors;
}
