import { HStack } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { ToggleIconButton } from "@design-system";
import { useTranslation } from "@hooks";
import { useProjectState, Project3DToolbarState } from "@state/project";
import { cursor } from "@utils/project/cursor.ts";
import { ReactElement, useEffect } from "react";
import { useCustomCursor } from "@hooks/useCustomCursor";
import { ColumnIcon, CommentIcon, PenIcon, SectionIcon, SelectIcon } from "./icons";

function Tool({
  toolName,
  keys,
  icon,
  disabled,
}: {
  toolName: Project3DToolbarState["_3DToolbarSelection"];
  keys: string | string[];
  icon: ReactElement;
  disabled?: boolean;
}) {
  const { t } = useTranslation("project3D.toolbarLeft.tools");
  const { _3DToolbarSelection, set3DToolbarSelection } = useProjectState();

  return (
    <ToggleIconButton
      key={toolName}
      isSelected={_3DToolbarSelection == toolName}
      onToggle={() => set3DToolbarSelection(toolName)}
      label={t(toolName + ".label")}
      icon={icon}
      shortcut={{
        label: t(toolName + ".label"),
        keys,
      }}
      placement="bottom"
      variant="inverse"
      disabled={disabled}
    />
  );
}

export function Tools3DToolbar(props: StyleProps) {
  const { _3DToolbarSelection } = useProjectState();
  const { setCursor } = useCustomCursor();

  useEffect(() => {
    setCursor(cursor(_3DToolbarSelection));
  }, [_3DToolbarSelection, setCursor]);

  return (
    <HStack gap={0} {...props}>
      <Tool toolName="select" icon={<SelectIcon />} keys="v" />
      <Tool toolName="pen" icon={<PenIcon />} keys="p" />
      <Tool toolName="section" icon={<SectionIcon />} keys="s" />
      <Tool toolName="column" icon={<ColumnIcon />} keys="c" />
      <Tool toolName="comment" keys={["ctrl+/", "meta+/"]} icon={<CommentIcon />} />
    </HStack>
  );
}
