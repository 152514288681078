import { Point } from "@models/backend";
import { Model, PathCollection, PathCollectionInput } from "@models/project";
import { Curve as V3dCurve, Zone } from "@variant-tech/pattern-derivation";
import { pick, zip } from "lodash";
import { Curve as Rhino3dmCurve, File3dm } from "rhino3dm";

const CURVE_SAMPLE = 50;

export function createPathCollection(): PathCollectionInput {
  return {
    controlVectors: [],
    type: "Path",
    points: [],
    isLoop: false,
    usage: null,
  };
}

export function sortPathCollections(collections: Array<PathCollection>, order: Model["zoneOrder"]) {
  const res: Array<PathCollection> = [];
  const found: Array<string> = [];
  if (order) {
    const sortedOrder = order.toSorted((a, b) => a[1] - b[1]);
    for (const pos of sortedOrder) {
      const id = pos[0];
      const collection = collections.find((collection) => collection.id == id);
      if (collection) {
        res.push(collection);
        found.push(id);
      }
    }
  }
  for (const collection of collections) {
    if (found.findIndex((id) => id === collection.id) === -1) {
      res.push(collection);
    }
  }
  return res;
}

export function getPathCollectionsFrom3dm(file3dm: File3dm): Array<PathCollectionInput> {
  const objects = file3dm.objects();
  const collections: Array<PathCollectionInput> = [];
  for (let i = 0; i < objects.count; i++) {
    const geometry = objects.get(i).geometry();
    if (geometry.objectType === window.rhino!.ObjectType.Curve) {
      const curve = geometry as Rhino3dmCurve;
      const domain = curve.domain;
      const step = (domain[1] - domain[0]) / (CURVE_SAMPLE - 1);
      const points: Array<Point> = [];
      for (let j = 0; j < CURVE_SAMPLE; j++) {
        const t = step * j;
        points.push(curve.pointAt(t) as Point);
      }
      collections.push({
        points,
        type: "Path",
        isLoop: curve.isClosed,
        controlVectors: points.map(() => [
          [0, 0, 0],
          [0, 0, 0],
        ]),
        usage: null,
      });
    }
  }
  return collections;
}

export function getPathCollectionsFromLayers(
  pathCollections: PathCollection[],
  { curves, guideCurve, zones }: { curves: V3dCurve[]; guideCurve?: V3dCurve; zones: Zone[] },
): PathCollection[] {
  const guideCurvePathCollection = pathCollections.find((p) => p.usage === "guide");
  const zonePathCollections = pathCollections.filter((p) => p.usage === "zone");
  const unusedAndGoringPathCollections = pathCollections.filter(({ usage }) => !usage || usage === "goring");

  const result: PathCollection[] = [];

  if (guideCurve && guideCurvePathCollection) {
    result.push({
      ...pick(guideCurvePathCollection, ["id", "name", "attributes"]),
      ...guideCurve,
      usage: "guide",
    });
  }

  result.push(
    ...zip(zonePathCollections, zones).map(
      ([left, right]): PathCollection => ({
        ...pick(left!, ["id", "name", "attributes"]),
        ...right!,
        usage: "zone",
      }),
    ),
  );

  result.push(
    ...zip(unusedAndGoringPathCollections, curves).map(
      ([left, right]): PathCollection => ({
        ...pick(left!, ["id", "name", "attributes"]),
        ...right!,
        usage: null,
      }),
    ),
  );

  return result;
}
