import { useFrame } from "@react-three/fiber";
import { useRef } from "react";
import { ArrowHelper, Material, Mesh, Vector3 } from "three";
import { useOcclusionChecker } from "./useOcclusionChecker";

interface ArrowHelperMaterial extends Material {
  depthTest: boolean;
}

type DirectionArrowProps = {
  mesh: Mesh;
  collectionId?: string;
  modelId: string;
  dir: Vector3;
  origin: Vector3;
  length: number;
  color: string;
  coneLength: number;
  coneRadius: number;
};
export function DirectionArrow({
  mesh,
  modelId,
  collectionId,
  dir,
  origin,
  length,
  color,
  coneLength,
  coneRadius,
}: DirectionArrowProps) {
  const { checkForOcclusion } = useOcclusionChecker({ mesh });
  const arrowRef = useRef<ArrowHelper>();

  useFrame(() => {
    function onChange(occluded: boolean) {
      if (arrowRef.current) {
        const coneMat = arrowRef.current.cone.material as ArrowHelperMaterial;
        const lineMat = arrowRef.current.line.material as ArrowHelperMaterial;
        coneMat.depthTest = occluded;
        lineMat.depthTest = occluded;
      }
    }

    checkForOcclusion(origin.toArray(), onChange);
  });

  const arrow = new ArrowHelper(dir, origin, length, color, coneLength, coneRadius);

  const coneMat = arrow.cone.material as ArrowHelperMaterial;
  const lineMat = arrow.line.material as ArrowHelperMaterial;

  arrow.cone.material = coneMat;
  arrow.cone.material.depthTest = false;
  arrow.cone.material.transparent = true;
  arrow.cone.material.opacity = 1.0;

  arrow.line.material = lineMat;
  arrow.line.material.depthTest = false;
  arrow.line.material.transparent = true;
  arrow.line.material.opacity = 0.0;

  arrow.line.userData = { modelId, collectionId };

  return <primitive object={arrow} ref={arrowRef} />;
}
