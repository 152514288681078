import { HStack, Input } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { IconButton, SendIcon, spacing, useSemanticTokens } from "@design-system";
import { useTranslation } from "@hooks";
import { useState } from "react";

export function AddComment({
  onAddComment,
  ...styles
}: {
  onAddComment: (content: string) => void;
} & StyleProps) {
  const { t } = useTranslation("project.comments");
  const [content, setContent] = useState<string>();
  const semanticTokens = useSemanticTokens();
  const onClickSend = async () => {
    if (content?.length) {
      await onAddComment?.(content);
      setContent("");
    }
  };

  return (
    <HStack
      gap={spacing.space["300"]}
      background={semanticTokens.surface.classic.secondary}
      p={spacing.space["100"]}
      {...styles}
    >
      <Input
        bg="none"
        height="32px"
        placeholder={t("add-comment.input.placeholder")}
        value={content}
        onChange={(e) => setContent(e.target.value)}
        onKeyUp={async ({ key }) => {
          if (key === "Enter") {
            await onClickSend();
          }
        }}
        autoFocus={true}
      />
      <IconButton
        variant="transparent"
        size="sm"
        isDisabled={!content?.length}
        onClick={onClickSend}
        hideLabel={true}
        label="Add comment"
        leftIcon={<SendIcon fill={semanticTokens.icons.accent.primary} />}
      />
    </HStack>
  );
}
