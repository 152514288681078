import { Box } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { forwardRef, ReactNode } from "react";
import { Vector2Like } from "three";

export type CommentPopupProps = { position?: Vector2Like; children: ReactNode } & Omit<StyleProps, "position">;
export const CommentPopup = forwardRef<HTMLDivElement, CommentPopupProps>(({ position, children, ...styles }, ref) => {
  const props = position ? { top: `${position.y}px`, left: `${position.x}px` } : {};

  return (
    <Box position="absolute" ref={ref} {...props} {...styles}>
      {children}
    </Box>
  );
});
