import BackendApi from "@api/BackendApi";
import { useAuth } from "@auth";
import { modelAttributes as defaultAttributes } from "@defaults";
import { useCurrentProject } from "@fragments/project/container/useCurrentProject";
import { useBackendPathCollection } from "@hooks";
import { ColumnAnchor, ModelAttributes, Project, SectionAnchor, Unit, Units } from "@models/backend";
import { Model } from "@models/project";
import { ModelsStore, useModelsStore } from "@state/models";
import { recordToArray, scaleMesh } from "@utils";
import { merge, pick } from "lodash";
import { useCallback } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useShallow } from "zustand/react/shallow";

const isValidCategory = (key: string): key is keyof ModelAttributes => {
  return [
    "knitStructure",
    "meshSettings",
    "normals",
    "stitchDensity",
    "sections",
    "columns",
    "bitmapSettings",
    "postprocessorDocument",
  ].includes(key);
};

export const useModelUpdate = ({ modelId }: { projectId?: Project["id"]; modelId: Model["id"] }) => {
  const {
    project: { id: projectId },
  } = useCurrentProject();
  const { headers } = useAuth();
  const { columns, pathCollections, pushPathCollections, sections, setColumnCurves, setSectionCurves, updateModel } =
    useModelsStore(
      ({
        columns,
        pathCollections,
        pushPathCollections,
        sections,
        setColumnCurves,
        setSectionCurves,
        updateModel,
      }) => ({
        columns,
        pathCollections,
        pushPathCollections,
        sections,
        setColumnCurves,
        setSectionCurves,
        updateModel,
      }),
    );

  const model: Model | undefined = useModelsStore(
    useShallow((state: ModelsStore) => state.models.find(({ id }) => id == modelId)),
  )!;

  const { replaceBackendPathCollections } = useBackendPathCollection();
  const updateBackendAttributes = useCallback(
    async (attributes: ModelAttributes) => {
      await BackendApi.patchProjectModel({
        headers,
        params: { projectId, id: modelId },
        body: { attributes },
      });
    },
    [headers, projectId, modelId],
  );

  const debouncedUpdateBackendAttributes = useDebouncedCallback(updateBackendAttributes, 1000);

  const changeAttributes = useCallback(
    async (changedAttributes: Partial<ModelAttributes>) => {
      const baseAttributes: ModelAttributes = {
        ...(model.attributes ?? {}),
      };

      const newAttributes = Object.entries(changedAttributes).reduce((acc, [key, value]) => {
        if (isValidCategory(key)) {
          return {
            ...acc,
            [key]: value
              ? {
                  ...(acc[key] ?? {}),
                  ...value,
                }
              : null,
          };
        }
        return acc;
      }, baseAttributes);

      updateModel(modelId, { attributes: newAttributes });
      await debouncedUpdateBackendAttributes(newAttributes);
    },
    [model.attributes, modelId, debouncedUpdateBackendAttributes, updateModel],
  );

  const changeModelUnit = useCallback(
    async (model: Model, unit: Unit) => {
      const scale = Units[unit];

      const {
        mesh,
        pathCollections: updatedPathCollections,
        columnCurves: updatedColumnCurves,
        sectionCurves: updatedSectionCurves,
      } = await scaleMesh(model, {
        scale,
        pathCollections: recordToArray(pathCollections[model.id]?.collections ?? {}),
        columnCurves: columns[model.id]?.columnCurves ?? [],
        sectionCurves: sections[model.id]?.sectionCurves ?? [],
        stitchDensity: merge({}, model.attributes?.stitchDensity ?? {}, defaultAttributes.stitchDensity),
      });

      await Promise.all([
        BackendApi.patchProjectModel({ headers, params: { projectId, id: model.id }, body: { unit } }),
        replaceBackendPathCollections(projectId, model.id, updatedPathCollections),
        BackendApi.updateColumnAnchors({
          headers,
          params: { projectId, modelId: model.id },
          body: {
            anchors: updatedColumnCurves.map(
              (c): ColumnAnchor => ({
                ...pick(c, ["id", "name", "attributes"]),
                point: c.points[0],
              }),
            ),
          },
        }),
        BackendApi.updateSectionAnchors({
          headers,
          params: { projectId, modelId: model.id },
          body: {
            anchors: updatedSectionCurves.map(
              (c): SectionAnchor => ({
                ...pick(c, ["id", "name", "attributes"]),
                point: c.points[0],
              }),
            ),
          },
        }),
      ]);

      updateModel(model.id, { mesh, unit });
      pushPathCollections(model.id, updatedPathCollections);
      setColumnCurves(model.id, updatedColumnCurves);
      setSectionCurves(model.id, updatedSectionCurves);
    },
    [
      headers,
      projectId,
      model,
      pathCollections,
      columns,
      sections,
      replaceBackendPathCollections,
      updateModel,
      pushPathCollections,
      setColumnCurves,
      setSectionCurves,
    ],
  );

  const changeUnit = useCallback(
    async (unit: Unit) => {
      if (!model) {
        return;
      }

      await Promise.all([
        changeModelUnit(model, unit),
        ...(model.references?.map((reference) => changeModelUnit(reference, unit)) ?? []),
      ]);
    },
    [model, changeModelUnit],
  );

  return { changeAttributes, changeUnit, model };
};
