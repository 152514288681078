import { Avatar, Flex } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { spacing, useSemanticTokens } from "@design-system";
import { CommentThread } from "@models/backend";
import { CommentThreadState } from "@state";
import { CommentPlacementIcon } from "./icons";

export function ThreadCallout({
  thread,
  state,
  ...styles
}: { thread: CommentThread; state: CommentThreadState } & StyleProps) {
  const {
    surface: { primary },
    border: { border },
    icons: {
      accent: { primary: accent },
    },
  } = useSemanticTokens();

  return (
    <Flex position="relative" alignItems="center" justifyContent="center" {...styles}>
      <CommentPlacementIcon
        fill={state?.visibility === "expanded" ? accent : primary}
        stroke={border}
        position="absolute"
      />
      <Avatar
        name={thread.author.name}
        src={thread.author.avatar}
        width={spacing.space[600]}
        height={spacing.space[600]}
        fontSize="xs"
        boxSize="2rem"
      />
    </Flex>
  );
}
