import { colors } from "@design-system/colors";
import { Model } from "@models/project";
import { useModelsStore } from "@state/models";
import { OnClickParams } from "@utils";
import { computeZoneKnitStructure } from "@utils/project/zone";
import { Zone as V3dZone, PathInput } from "@variant-tech/pattern-derivation";
import { useEffect, useState } from "react";
import { BufferAttribute, BufferGeometry, MeshPhysicalMaterial } from "three";
import { workspace3dTokens } from "./workspace-3d-tokens";
import { getV3dApi } from "@utils/project/initV3dApi";

export interface ZoneProps {
  model: Model;
  pathCollectionId: string;
  index: number;
  onClick?: (params: OnClickParams) => void;
}

export function Zone({ model, index, pathCollectionId, onClick }: ZoneProps) {
  const knitStructures = useModelsStore((state) => state.knitStructures);
  const pathCollection = useModelsStore(
    ({ pathCollections }) => pathCollections?.[model.id]?.collections[pathCollectionId],
  );

  const defaultKnitStructure = knitStructures.find(({ isDefault }) => isDefault);
  const selectedKnitStructure = computeZoneKnitStructure(pathCollection, model) ?? defaultKnitStructure;
  const [geometry, setGeometry] = useState<BufferGeometry>();
  const [material, setMaterial] = useState<MeshPhysicalMaterial>();

  useEffect(() => {
    async function updateZoneGeometry() {
      if (!pathCollection || pathCollection.usage !== "zone") {
        setGeometry(undefined);
        return;
      }
      const zone: V3dZone = await getV3dApi().generateZone(model.mesh3DBase, pathCollection as PathInput);
      const faceIndices = Array.from(zone.faces);
      const geom = new BufferGeometry();
      geom.setIndex(faceIndices);
      geom.setAttribute("position", new BufferAttribute(zone.vertices, 3));
      geom.computeVertexNormals();
      setGeometry(geom);
    }
    updateZoneGeometry();
  }, [pathCollection, model.mesh3DBase]);

  useEffect(() => {
    setMaterial(
      new MeshPhysicalMaterial({
        color: selectedKnitStructure?.color ?? colors.gray["200"],
        polygonOffset: true,
        polygonOffsetUnits: 1,
        polygonOffsetFactor: -1 - index,
        ...workspace3dTokens.material,
      }),
    );
  }, [selectedKnitStructure, index]);

  return (
    geometry &&
    material && (
      <mesh
        geometry={geometry}
        material={material}
        renderOrder={index}
        onClick={(e) => onClick?.({ ...e, id: pathCollectionId })}
      />
    )
  );
}
