import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

export function DesignViewIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 20 20" height="5" width="5" {...props}>
      <circle cx="10" cy="10" r="9" stroke="currentColor" fill="none" stroke-width="1.5" />
    </Icon>
  );
}
