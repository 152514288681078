import { useProgressToast, useTranslation } from "@hooks";
import { useModelLoader } from "@hooks/project";
import { KnitStructure, Model as BackendModel, Project } from "@models/backend";
import { Model, PathCollection } from "@models/project";
import { useModelsStore, useProjectState } from "@state";
import { isVoidPathCollection } from "@utils";
import { computeStitchDensity } from "@utils/project/knitProcess.ts";
import { getMeshPayload } from "@utils/project/mesh.ts";
import { omit } from "lodash";
import { useEffect } from "react";
import { useProcessLoader } from "./useProcessLoader";

type UseSavedModelProps = {
  triggerUploadModelModal: () => void;
  project: Project;
};

export function useLoadProject({ project, triggerUploadModelModal }: UseSavedModelProps) {
  const {
    clear: clearModels,
    knitStructures,
    pushPathCollections,
    setColumnCurves,
    setSectionCurves,
  } = useModelsStore(({ knitStructures, clear, setColumnCurves, setSectionCurves, pushPathCollections }) => ({
    clear,
    knitStructures,
    pushPathCollections,
    setColumnCurves,
    setSectionCurves,
  }));
  const { t } = useTranslation("hooks.models.load.progress");
  const toast = useProgressToast();
  const { loadModel } = useModelLoader();
  const { loadLastProcess } = useProcessLoader();
  const { _3DToolbarSelection, set3DToolbarSelection, renderModeSelection, setRenderModeSelection } = useProjectState();

  async function computeGridCurves(
    model: Model,
    backendModel: BackendModel,
    guideSource: PathCollection,
    pathCollections: PathCollection[],
    stitchDensity: { course: number; wale: number },
  ) {
    const { columnCurves, sectionCurves } = await getMeshPayload(
      model,
      guideSource,
      pathCollections,
      backendModel.columnAnchors.map(({ point }) => point).filter((p) => p.length === 3),
      backendModel.sectionAnchors.map(({ point }) => point).filter((p) => p.length === 3),
      stitchDensity,
    );

    return {
      columnCurves: columnCurves.map((curve, index) => ({
        ...omit(backendModel.columnAnchors[index], "point"),
        ...curve,
        name: `Column ${index + 1}`,
      })),
      sectionCurves: sectionCurves.map((curve, index) => ({
        ...omit(backendModel.sectionAnchors[index], "point"),
        ...curve,
        name: `Section ${index + 1}`,
      })),
    };
  }

  async function loadGridCurves(
    backendModel: BackendModel,
    model: Model,
    pathCollections: PathCollection[],
    defaultKnitStructure?: KnitStructure,
  ) {
    const guideSource = pathCollections?.find(({ usage }) => usage === "guide");

    if (!guideSource) {
      return undefined;
    }

    const filteredPathCollections = pathCollections.filter(({ usage }) => usage === "zone" || usage === "goring");
    const stitchDensity = computeStitchDensity(model, defaultKnitStructure);
    const gridCurves = await computeGridCurves(
      model,
      backendModel,
      guideSource,
      filteredPathCollections,
      stitchDensity,
    );

    if (gridCurves) {
      setColumnCurves(model.id, gridCurves.columnCurves);
      setSectionCurves(model.id, gridCurves.sectionCurves);
    }

    return gridCurves;
  }

  const loadProject = async (project: Project) => {
    if (_3DToolbarSelection !== "select") {
      set3DToolbarSelection("select");
    }
    if (renderModeSelection !== "design") {
      setRenderModeSelection("design");
    }

    clearModels();
    const defaultKnitStructure = knitStructures.find(({ isDefault }) => isDefault);

    for (const backendModel of project.models) {
      const { update } = toast(t, { title: backendModel.name, status: "in-progress" });

      try {
        const model = await loadModel(backendModel);

        // TODO: add progress update etc.
        await loadLastProcess(project.id);

        if (!backendModel.parentId) {
          update({
            message: t(`status.in-progress.path-collections`),
            status: "in-progress",
          });

          const pathCollections: PathCollection[] = backendModel.pathCollections.map(
            ({ paths, name, usage, attributes, id }) => {
              const isVoid = isVoidPathCollection({ attributes }, model);
              const { isLoop, controlVectors, points, type } = paths[0] ?? {
                isLoop: false,
                controlVectors: [],
                points: [],
                type: "Path",
              };

              return {
                id,
                usage,
                name,
                isLoop: isLoop ?? false,
                attributes,
                controlVectors,
                isVoid,
                points,
                type,
              };
            },
          );

          pushPathCollections(model.id, pathCollections);

          try {
            await loadGridCurves(backendModel, model, pathCollections, defaultKnitStructure);
            update({ status: "success" });
          } catch (e) {
            console.warn("Error loading grid curves:", e);
            update({
              status: "error",
              message: t("status.error.gridCurves"),
            });
          }
        } else {
          update({ status: "success" });
        }
      } catch (error) {
        console.error("Error loading backend model", backendModel, error);

        update({
          status: "error",
          message: t("status.error.loadFailed"),
        });

        if (!backendModel.parentId && triggerUploadModelModal) {
          triggerUploadModelModal();
        }
      }
    }

    if (!project.models?.length && triggerUploadModelModal) {
      triggerUploadModelModal();
    }
  };

  useEffect(() => {
    loadProject(project);
  }, [project]);
}
