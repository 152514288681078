import { useToast } from "@design-system";
import { useBackendColumnAnchor, useBackendSectionAnchor, useTranslation } from "@hooks";
import { ColumnAnchor, Project, SectionAnchor } from "@models/backend";
import { ColumnCurveInput, GridCurve, GridCurveInput, Model, SectionCurveInput } from "@models/project";
import { useModelsStore } from "@state/models";
import {
  computeColumnCurves as v3dComputeColumnCurves,
  computeSectionCurves as v3dComputeSectionCurves,
  generateGridCurves as v3dGenerateGridCurves,
} from "@utils/project";
import { PathInput, Vector3 } from "@variant-tech/pattern-derivation";
import { useCallback } from "react";

type UseCalibrationCurvesProps = {
  project: Project;
  model: Model;
};

function findByAnchorPoint<T>(curves: GridCurveInput<T>[], anchorPoint: Vector3): GridCurveInput<T> | undefined {
  const expected = anchorPoint.map((it) => it.toFixed(5));

  return curves.find((curve) => {
    const actual = curve.points[0].map((v) => v.toFixed(5));
    const result = actual.map((v, i) => expected[i] == v);

    return result.reduce((a, b) => a && b, true);
  });
}

function findAnchorForCurve<T, A extends ColumnAnchor | SectionAnchor>(
  curve: GridCurveInput<T>,
  anchors: A[],
): A | undefined {
  const expected = curve.points[0].map((it) => it.toFixed(5));

  return anchors.find((anchor) => {
    const actual = anchor.point.map((v) => v.toFixed(5));
    const result = actual.map((v, i) => expected[i] == v);

    return result.reduce((a, b) => a && b, true);
  });
}

function enrichCurvesFromResponse<T>(
  curves: Array<GridCurveInput<T>>,
  response: (ColumnAnchor | SectionAnchor)[],
  prefix: string,
): GridCurve<T>[] {
  return curves
    .map((curve) => ({ curve, anchor: findAnchorForCurve(curve, response) }))
    .filter((p) => p.anchor)
    .map(({ curve, anchor }, index) => ({
      ...curve,
      id: anchor!.id,
      name: `${prefix} ${index + 1}`,
    }));
}

export const useCalibrationCurves = ({ project, model }: UseCalibrationCurvesProps) => {
  const toast = useToast();
  const { t } = useTranslation("hooks.project.calibration");
  const {
    columns,
    sections,
    pathCollections: pathCollectionsSlice,
    removeSectionCurve: removeSectionCurveSlice,
    setSectionCurves: setSectionCurvesSlice,
    removeColumnCurve: removeColumnCurveSlice,
    setColumnCurves: setColumnCurvesSlice,
  } = useModelsStore();
  const { updateBackendSectionAnchors, deleteBackendSectionAnchor } = useBackendSectionAnchor();
  const { updateBackendColumnAnchors, deleteBackendColumnAnchor } = useBackendColumnAnchor();

  const getSectionCurves = useCallback(
    (modelId: string) => {
      return sections[modelId].sectionCurves;
    },
    [sections],
  );

  const getColumnCurves = useCallback(
    (modelId: string) => {
      return columns[modelId].columnCurves;
    },
    [columns],
  );

  const pathCollections = Object.values(pathCollectionsSlice[model.id]?.collections);

  const removeSectionCurve = async (sectionAnchorId: string) => {
    await deleteBackendSectionAnchor(project.id, model.id, sectionAnchorId);
    removeSectionCurveSlice(model.id, sectionAnchorId);
  };

  const setColumnCurves = async (curves: Array<ColumnCurveInput>) => {
    const response = await updateBackendColumnAnchors(
      project.id,
      model.id,
      curves.map((curve, index) => ({
        ...curve,
        name: `Column ${index + 1}`,
      })),
    );
    const result = enrichCurvesFromResponse(curves, response, "Column");
    setColumnCurvesSlice(model.id, result);
    return result;
  };

  const setSectionCurves = async (curves: Array<SectionCurveInput>) => {
    const response = await updateBackendSectionAnchors(
      project.id,
      model.id,
      curves.map((curve, index) => ({
        ...curve,
        name: `Section ${index + 1}`,
      })),
    );

    const result = enrichCurvesFromResponse(curves, response, "Section");
    setSectionCurvesSlice(model.id, result);
    return result;
  };

  const removeColumnCurve = async (columnAnchorId: string) => {
    await deleteBackendColumnAnchor(project.id, model.id, columnAnchorId);
    removeColumnCurveSlice(model.id, columnAnchorId);
  };

  const computeColumnCurve = async (guideSource: PathInput, curve: ColumnCurveInput) => {
    try {
      const curves = getColumnCurves(model.id);
      const gridCurves = await v3dComputeColumnCurves(model, guideSource, pathCollections, [
        ...curves.map((curve) => curve.points[0]),
        curve.points[0],
      ]);
      await setColumnCurves(
        gridCurves.map(
          (curve) =>
            findByAnchorPoint(curves, curve.points[0]) ?? {
              ...curve,
              attributes: { source: "user" },
            },
        ),
      );
    } catch (error) {
      console.log({ message: "Failed to update column curves", error });
      const message = <p dangerouslySetInnerHTML={{ __html: t("column-curves.error.message") }}></p>;
      toast({
        title: t("column-curves.error.title"),
        children: message,
        variant: "error",
        isCloseable: true,
        duration: 3000,
      });
    }

    return getColumnCurves(model.id);
  };

  const computeSectionCurve = async (guideSource: PathInput, curve: SectionCurveInput) => {
    try {
      const curves = getSectionCurves(model.id);
      const gridCurves = await v3dComputeSectionCurves(model, guideSource, pathCollections, [
        ...curves.map((curve) => curve.points[0]),
        curve.points[0],
      ]);
      await setSectionCurves(
        gridCurves.map(
          (curve) =>
            findByAnchorPoint(curves, curve.points[0]) ?? {
              ...curve,
              attributes: { source: "user" },
            },
        ),
      );
    } catch (error) {
      console.log({ message: "Failed to update section curves", error });
      const message = <p dangerouslySetInnerHTML={{ __html: t("section-curves.error.message") }}></p>;
      toast({
        title: t("section-curves.error.title"),
        children: message,
        variant: "error",
        isCloseable: true,
        duration: 3000,
      });
    }

    return getSectionCurves(model.id);
  };

  const generateGridCurves = async (
    guideSource: PathInput | undefined,
    sectionsCount: number,
    columnsCount: number,
  ) => {
    if (!guideSource || (guideSource?.points?.length ?? 0) < 2) {
      await setSectionCurves([]);
      return { columnCurves: [], sectionCurves: [] };
    }

    try {
      const gridCurves = await v3dGenerateGridCurves(model, guideSource, pathCollections, columnsCount, sectionsCount);

      if (columnsCount) {
        await setColumnCurves(gridCurves.columnCurves.map((curve) => ({ ...curve, attributes: { source: "auto" } })));
      }

      if (sectionsCount) {
        await setSectionCurves(gridCurves.sectionCurves.map((curve) => ({ ...curve, attributes: { source: "auto" } })));
      }

      return gridCurves;
    } catch (error) {
      console.log({ message: "Failed to get section curves", error });
      const message = <p dangerouslySetInnerHTML={{ __html: t("section-curves.error.message") }}></p>;
      toast({
        title: t("section-curves.error.title"),
        children: message,
        variant: "error",
        isCloseable: true,
        duration: 3000,
      });
    }
  };

  const updateSectionCurve = async (modelId: string, sectionId: string, attributes: { excludeGoring: boolean }) => {
    try {
      const curves = getSectionCurves(modelId);
      const sectionIndex = curves.findIndex((curve) => curve.id === sectionId);

      if (sectionIndex === -1) {
        console.error("Section curve not found");
        return null;
      }

      const currentCurve = curves[sectionIndex];
      const updatedCurves = [...curves];

      updatedCurves[sectionIndex] = {
        ...currentCurve,
        attributes: {
          ...(currentCurve.attributes || {}),
          ...attributes,
        },
      };

      const response = await setSectionCurves(updatedCurves);
      return response?.[sectionIndex] || null;
    } catch (error) {
      console.log({ message: "Failed to update section curve", error });
      toast({
        title: t("section-curves.error.title"),
        children: t("section-curves.error.description"),
        variant: "error",
        isCloseable: true,
        duration: 3000,
      });
      return null;
    }
  };

  return {
    computeColumnCurve,
    computeSectionCurve,
    generateGridCurves,
    getColumnCurves,
    getSectionCurves,
    removeSectionCurve,
    setSectionCurves,
    removeColumnCurve,
    setColumnCurves,
    updateSectionCurve,
  };
};
