import { usePath, usePointerState } from "@hooks";
import { useCustomCursor } from "@hooks/useCustomCursor";
import { Project } from "@models/backend";
import { Model } from "@models/project";
import { useThree } from "@react-three/fiber";
import { useModelsStore } from "@state/models";
import { useProjectState } from "@state/project";
import { vector3ToScreenSpace } from "@utils/project/raycaster";
import { findClosestPointOnLine, flatArrayToVector3Array } from "@utils/project/tools/penTool";
import { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Vector3 } from "three";
import { useDebouncedCallback } from "use-debounce";
import { ClickTarget } from "./ClickTarget";
import { Path } from "./Path";
import { workspace3dTokens } from "./workspace-3d-tokens";

type PenToolProps = {
  project: Project;
  model: Model;
};

// NOTE: this operates more like a PENCIL tool due to useFrame
export function PenTool({ project, model }: PenToolProps) {
  const { mesh } = model;
  const { hoveredObject, pathCollections, selectedObjects, setSelectedObjects } = useModelsStore();

  const hoveredPath =
    hoveredObject && hoveredObject.type === "path" ? pathCollections[model.id].collections[hoveredObject.id] : null;

  const { appendPoint, insertPoint, pushPath, removePoints, pathCollection, removePath } = usePath(
    project,
    model,
    hoveredPath ? hoveredPath : pathCollections[model.id].newCollection,
  );

  const { setCursor } = useCustomCursor();
  const [hovered, setHovered] = useState<number | undefined>(undefined);

  const { set3DToolbarSelection } = useProjectState();

  useHotkeys("enter", () => push());
  useHotkeys("escape", () => abort());
  useHotkeys("shift+enter", () => push(true));

  const push = (close = false) => {
    if (pathCollection.points.length > 1) {
      const minPoints = pathCollection.type === "BezierPath" ? 2 : 3;
      const canClose = pathCollection.points.length >= minPoints;

      pushPath(close && canClose);
      setHovered(undefined);
      setCursor("pen");
    }
  };

  const abort = async () => {
    if (pathCollection.points.length > 0) {
      await removePath();
    } else {
      setHovered(undefined);
      set3DToolbarSelection("select");
    }
  };

  // state is too slow for useFrame
  const { raycaster, camera, size } = useThree();
  const { pointer0Down, altKey } = usePointerState();
  const { points } = pathCollection;
  const minDistance = workspace3dTokens.penTool.minDistance;

  useEffect(() => {
    if (altKey) {
      setCursor("penMinus");
    } else {
      if (hovered !== undefined && hovered !== points.length - 1) {
        setCursor("penCoincident");
      } else if (hoveredObject) {
        setCursor("penPlus");
      } else {
        setCursor("pen");
      }
    }
  }, [altKey, hovered, points, hoveredObject]);

  const hoverProcess = async () => {
    if (!pointer0Down || altKey) {
      return;
    }

    if (!hoveredPath || hoveredObject?.type !== "path" || hoveredObject.segmentIdx === undefined) {
      return;
    }

    const hit = raycaster.intersectObject(mesh)[0];
    if (!hit) {
      return;
    }

    const hit2D = vector3ToScreenSpace(new Vector3(...hit.point), camera, size);
    const points2D = points.map((point) => {
      return vector3ToScreenSpace(new Vector3(...point), camera, size);
    });

    if (points2D.length && points2D.some((pos) => hit2D.distanceTo(pos) < minDistance)) {
      return;
    }

    if (hoveredObject?.type === "path" && hoveredObject.curveSegment) {
      if (hoveredObject.curveSegment.vertices) {
        const points = flatArrayToVector3Array(hoveredObject.curveSegment.vertices);
        const closestPoint = findClosestPointOnLine(points, hit.point);
        const insertAt = hoveredObject.segmentIdx;

        if (closestPoint && insertAt !== undefined) {
          const closestPointPos: [number, number, number] = [closestPoint.x, closestPoint.y, closestPoint.z];
          await insertPoint(closestPointPos, insertAt);
          setSelectedObjects([{ type: "path", id: hoveredObject.id, modelId: model.id }]);
        }
      }
    }
  };

  async function removePoint(index: number) {
    await removePoints([index]);
  }

  const clickTargets = points.map((sp, index) => {
    return (
      <ClickTarget
        key={index}
        scale={workspace3dTokens.surfacePoint.outerRadius * 2}
        tolerance={0}
        position={new Vector3(...sp)}
        onClick={() => {
          if (altKey) {
            removePoint(index);
            setHovered(undefined);
          } else {
            if (index == points.length - 1) {
              push();
            }
          }
        }}
        onHoveredChange={(hovered: boolean) => {
          if (hovered) {
            if (!altKey && index !== points.length - 1) {
              setCursor("penCoincident");
            }
            setHovered(index);
          } else {
            setCursor(altKey ? "penMinus" : "pen");
            setHovered(undefined);
          }
        }}
      />
    );
  });

  useEffect(() => {
    if (!pointer0Down || altKey || hoveredObject) {
      return;
    }
    const hit = raycaster.intersectObject(mesh)[0];
    if (!hit) {
      return;
    }

    if (selectedObjects.length) {
      setSelectedObjects([]);
    }

    const hit2D = vector3ToScreenSpace(new Vector3(...hit.point), camera, size);
    const points2D = points.map((point) => {
      return vector3ToScreenSpace(new Vector3(...point), camera, size);
    });

    if (points2D.length && hit2D.distanceTo(points2D.slice(-1)[0]) < minDistance) {
      return;
    }

    let pos: [number, number, number] = [hit.point.x, hit.point.y, hit.point.z];

    let close = false;

    // snap to existing point
    if (points2D.filter((pt) => pt.distanceTo(hit2D) < minDistance).length > 0) {
      const point = points2D.filter((pt) => pt.distanceTo(hit2D) < minDistance)[0];
      const pointIdx = points2D.indexOf(point);
      pos = points[pointIdx];

      if (pointIdx === 0) {
        close = true;
      }
    }

    if (close) {
      push(true);
      return;
    }

    async function process() {
      await appendPoint(pos);
    }

    process();
  }, [pointer0Down, altKey]);

  const debouncedHoverProcess = useDebouncedCallback(hoverProcess);
  if (hoveredObject) {
    debouncedHoverProcess();
  }

  return (
    <>
      {clickTargets}
      <Path project={project} model={model} />
    </>
  );
}
