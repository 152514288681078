import { Model, PathCollection } from "@models/project";
import { useFrame, useThree } from "@react-three/fiber";
import { getV3dApi } from "@utils/project/initV3dApi.ts";
import { isVoidPathCollection } from "@utils/project/zone.ts";
import { GridCurve, Part, Vector3 } from "@variant-tech/pattern-derivation";
import { merge, pick } from "lodash";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

interface UseGridCurvePreviewProps {
  guideSource: PathCollection;
  model: Model;
  mode: "column" | "section" | string;
  pathCollections: PathCollection[];
  setPreviewGridCurve: Dispatch<SetStateAction<GridCurve | null>>;
}

async function createPart(guideSource: PathCollection, model: Model, pathCollections: PathCollection[]) {
  const zoneCollections = pathCollections.filter((c) => c.usage === "zone");
  const v3dApi = getV3dApi();
  const mesh3DBase = model.mesh3DBase;
  if (!guideSource.isLoop) {
    guideSource.isLoop = false;
  }
  const state = merge(
    { type: "Path", isLoop: false },
    pick(guideSource, ["type", "points", "controlVectors", "isLoop"]),
  );
  const path = await (state.type === "BezierPath"
    ? v3dApi.bezierPathFromState(mesh3DBase, state)
    : v3dApi.pathFromState(mesh3DBase, state));
  const zones = await Promise.all(
    zoneCollections.map((zone) =>
      v3dApi.pathCollectionFromState(mesh3DBase, [
        merge({ type: "Path", isLoop: false }, pick(zone, ["type", "points", "controlVectors", "isLoop"])),
      ]),
    ),
  );
  const part = await v3dApi.Part.create(
    mesh3DBase,
    path,
    zones,
    zoneCollections.map((c) => isVoidPathCollection(c, model)),
  );
  await part.computeFieldValues();
  return part;
}

async function generateGridCurve(mode: string, part: Part | undefined, point: Vector3): Promise<GridCurve | null> {
  if (!part) {
    return null;
  }

  try {
    const segments = await (mode === "section"
      ? part.computeSectionCurveAsFloats(point)
      : part.computeColumnCurveAsFloats(point));

    return { points: [point], segments: segments.map((vertices) => ({ vertices })) };
  } catch {
    return null;
  }
}

export function useGridCurvePreview({
  guideSource,
  model,
  mode,
  setPreviewGridCurve,
  pathCollections,
}: UseGridCurvePreviewProps) {
  const { raycaster } = useThree();
  const [part, setPart] = useState<Part>();
  const getPreviewGridCurve = useDebouncedCallback(generateGridCurve);

  useEffect(() => {
    createPart(guideSource, model, pathCollections).then(setPart);

    return () => {
      part?.delete()?.catch(console.warn);
    };
  }, [guideSource, model, pathCollections]);

  useFrame(async () => {
    const hit = raycaster.intersectObject(model.mesh)[0];

    if (!hit) {
      setPreviewGridCurve(null);
      return;
    }

    const curve = await getPreviewGridCurve(mode, part, [hit.point.x, hit.point.y, hit.point.z]);

    if (curve) {
      setPreviewGridCurve(curve);
    }
  });
}
