import { KnitStructure } from "@models/backend";
import { Model, PathCollection } from "@models/project";
import { ZoneInput } from "@variant-tech/pattern-derivation";

export const isVoidKnitStructure = (knitStructure?: KnitStructure) => knitStructure?.mode === "Void";

export function computeZoneKnitStructure(pathCollection: Pick<PathCollection, "attributes">, model: Model) {
  return pathCollection.attributes?.knitStructure ?? model.attributes.knitStructure;
}

export function isVoidPathCollection(zone: Pick<PathCollection, "attributes">, model: Model) {
  return isVoidKnitStructure(computeZoneKnitStructure(zone, model));
}

export function findZones(pathCollections: PathCollection[]) {
  return pathCollections.filter((pathCollection) => pathCollection.usage === "zone");
}

export function computeZonesFromPathCollections(pathCollections: PathCollection[], model: Model): ZoneInput[] {
  return findZones(pathCollections)
    .map((zone) => ({ ...zone, isVoid: isVoidPathCollection(zone, model) }))
    .map(({ points, controlVectors, isLoop, isVoid, type }) => ({
      points,
      controlVectors,
      isLoop,
      isVoid,
      type,
    }));
}
