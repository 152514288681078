import { workspace3dTokens } from "@fragments/project/workspace-3d/workspace-3d-tokens";
import { ColumnCurve, Model, PathCollection, SectionCurve } from "@models/project";
import {
  computeZonesFromPathCollections,
  findGoringCurves,
  findGuideCurve,
  findUnusedAndGoringCurves,
  getCurvesFromGridCurves,
  getPathCollectionsFromLayers,
} from "@utils";
import { getV3dApi } from "@utils/project/initV3dApi.ts";
import { Geometry, Mesh3DBase, Vector3 } from "@variant-tech/pattern-derivation";
import save from "file-saver";
import { File3dm } from "rhino3dm";
import {
  BufferAttribute,
  BufferGeometry,
  DoubleSide,
  Group,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
  Object3D,
  Scene,
} from "three";
import { GLTFExporter } from "three/addons/exporters/GLTFExporter.js";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader.js";

export type WorkModel = Pick<Model, "mesh" | "mesh3DBase">;

function arrayBufferToBlob(buffer: ArrayBuffer) {
  return new Blob([buffer], { type: "application/octet-stream" });
}

function stringToBlob(text: string) {
  return new Blob([text], { type: "text/plain" });
}

function getLoader(ext: string) {
  switch (ext) {
    case "obj":
      return new OBJLoader();
    case "stl":
      return new STLLoader();
    case "gltf":
      return new GLTFLoader();
    case "glb":
      return new GLTFLoader();
    default:
      throw new Error(`Unsupported file extension: ${ext}`);
  }
}

export async function loadBasicMesh(url: string, ext: string) {
  const loader = getLoader(ext);

  return loader
    .loadAsync(url)
    .then((obj) => {
      return obj;
    })
    .catch((err) => {
      console.log("error loading model:", { err, url });
      return null;
    });
}

function nextPower(n: number) {
  const power = Math.ceil(Math.log2(n));
  return 2 ** power;
}

export function loadImageData(bmpUrl: string): Promise<ImageData> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "Anonymous";
    image.onload = function () {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        reject(new Error("Could not create context"));
        return;
      }
      ctx.canvas.width = image.width;
      ctx.canvas.height = image.height;
      ctx.save();
      ctx.scale(1, -1);
      ctx.drawImage(image, 0, 0, image.width, -1 * image.height);
      ctx.restore();
      const data = ctx.getImageData(0, 0, image.width, image.height);
      canvas.remove();
      resolve(data);
    };
    image.src = bmpUrl;
  });
}

export async function loadKnitMesh(objUrl: string, bmpUrl: string) {
  const group = await loadBasicMesh(objUrl, "obj");
  if (!group) {
    throw new Error("No mesh was loaded.");
  }
  const bmpData = await loadImageData(bmpUrl);
  const mesh = (group as Group).children[0] as Mesh;
  const p2dim = nextPower(Math.max(bmpData.width, bmpData.height));
  const positionsArray = mesh.geometry.attributes.position.array;
  const nVertices = mesh.geometry.attributes.position.count;
  const colorArray = new Float32Array(nVertices * 3);
  const uvArray = mesh.geometry.attributes.uv.array;
  const nTriangles = nVertices / 3;
  const edgeIndices = [];
  const edgePositionArray = [];

  for (let i = 0; i < nTriangles; i++) {
    let x = 0;
    let y = 0;
    const uvOfTriangle = [];
    for (let j = 0; j < 3; j++) {
      uvOfTriangle.push(uvArray[(i * 3 + j) * 2]);
      uvOfTriangle.push(uvArray[(i * 3 + j) * 2 + 1]);
    }

    for (let j = 0; j < 3; j++) {
      // find the uv of the current vertex
      const currX = uvOfTriangle[j * 2];
      const currY = uvOfTriangle[j * 2 + 1];

      // find the uv of the next vertex
      const next = j < 2 ? j + 1 : 0;
      const nextX = uvOfTriangle[next * 2];
      const nextY = uvOfTriangle[next * 2 + 1];

      if (nextX == currX || nextY == currY) {
        edgeIndices.push(i * 3 + j);
        edgeIndices.push(i * 3 + next);

        edgePositionArray.push(positionsArray[(i * 3 + j) * 3]);
        edgePositionArray.push(positionsArray[(i * 3 + j) * 3 + 1]);
        edgePositionArray.push(positionsArray[(i * 3 + j) * 3 + 2]);

        edgePositionArray.push(positionsArray[(i * 3 + next) * 3]);
        edgePositionArray.push(positionsArray[(i * 3 + next) * 3 + 1]);
        edgePositionArray.push(positionsArray[(i * 3 + next) * 3 + 2]);

        edgePositionArray.push(positionsArray[(i * 3 + next) * 3]);
        edgePositionArray.push(positionsArray[(i * 3 + next) * 3 + 1]);
        edgePositionArray.push(positionsArray[(i * 3 + next) * 3 + 2]);
      }

      // increment x, y
      x += currX;
      y += currY;
    }

    x /= 3;
    y /= 3;
    x = Math.floor(x * p2dim);
    y = Math.floor(y * p2dim);

    for (let j = 0; j < 3; j++) {
      const xc = (i * 3 + j) * 3;
      const xi = (y * bmpData.width + x) * 4;
      colorArray[xc] = bmpData.data[xi] / 255.0;
      colorArray[xc + 1] = bmpData.data[xi + 1] / 255.0;
      colorArray[xc + 2] = bmpData.data[xi + 2] / 255.0;
    }
  }

  const color = new BufferAttribute(colorArray, 3);
  mesh.geometry.setAttribute("color", color);
  const material = new MeshBasicMaterial({
    ...workspace3dTokens.knitMesh.material,
  });
  mesh.material = material;

  const quadGeometry = new BufferGeometry();

  const edgeObj = new Float32Array(edgePositionArray.length * 3);
  for (let i = 0; i < edgePositionArray.length; i++) {
    edgeObj[i * 3] = edgePositionArray[i];
    edgeObj[i * 3 + 1] = edgePositionArray[i + 1];
    edgeObj[i * 3 + 2] = edgePositionArray[i + 2];
  }

  quadGeometry.setAttribute("position", new BufferAttribute(new Float32Array(edgePositionArray), 3));

  const quadMesh = new Mesh(quadGeometry);

  return { mesh, quadMesh };
}

export async function getGeoPositionsArray(url: string, ext: string) {
  const object = await loadBasicMesh(url, ext);
  const group = new Group();

  // if model is an STL, we can return the geometry directly
  if (ext === "stl") {
    return (object as BufferGeometry).attributes.position.array;
  }

  // whereas for GLTF/GLB and OBJ we need to add it to a Group which we can then traverse
  if (ext === "gltf" || ext === "glb") {
    group.add((object as GLTF).scene);
  } // ... OBJ
  else {
    group.add(object as Object3D);
  }

  let positionsArray: ArrayLike<number> = [];

  // and then return the geometry from the first mesh we find as BufferGeometry
  group.traverse((child: Object3D) => {
    if ((child as unknown) instanceof Mesh) {
      let childGeometry = (child as Mesh).geometry as BufferGeometry;
      if (childGeometry.index) {
        childGeometry = childGeometry.toNonIndexed();
      }
      positionsArray = childGeometry.attributes.position.array;
    }
  });
  return positionsArray;
}

export function getMeshFromGeometry({ vertices, faces }: Geometry) {
  const bufferGeometry = new BufferGeometry();

  bufferGeometry.setIndex(Array.from(faces));
  bufferGeometry.setAttribute("position", new BufferAttribute(vertices, 3));
  bufferGeometry.computeVertexNormals();

  return new Mesh(bufferGeometry, new MeshStandardMaterial({ color: "lightgray", side: DoubleSide }));
}

export async function exportMeshToGLB(mesh: Mesh, name = "scene", binary = true, debug = false): Promise<File> {
  const exportScene = new Scene().add(mesh);

  const gltfExporter = new GLTFExporter();

  const options = {
    trs: false,
    onlyVisible: true,
    binary,
    maxTextureSize: 4096,
  };

  return new Promise<File>((resolve, reject) => {
    gltfExporter.parse(
      exportScene,
      function (result) {
        let blob;
        let filename;
        if (result instanceof ArrayBuffer) {
          blob = arrayBufferToBlob(result);
          filename = `${name}.glb`;
        } else {
          const output = JSON.stringify(result, null, 2);
          blob = stringToBlob(output);
          filename = `${name}.gltf`;
        }
        const file = new File([blob], filename, { type: blob.type });
        if (debug) {
          save(file, filename);
        }
        resolve(file);
      },
      reject,
      options,
    );
  });
}

export async function initMesh(
  bufferedPositions: ArrayLike<number>,
  {
    flipNormal,
    scale,
  }: {
    flipNormal: boolean;
    scale: number;
  },
): Promise<{
  mesh: Mesh;
  mesh3DBase: Mesh3DBase;
}> {
  const v3dApi = getV3dApi();
  const mesh3DBase = (await v3dApi.createMesh(bufferedPositions as Float32Array, { flipNormal })) as Mesh3DBase;
  const { faces, vertices } = await v3dApi.initializeMesh(mesh3DBase, { scale });
  const mesh = getMeshFromGeometry({ vertices, faces });
  return { mesh3DBase, mesh };
}

export async function importMesh(
  path: string,
  fileName: string,
  options: {
    flipNormal: boolean;
    scale: number;
  },
): Promise<WorkModel> {
  const ext = fileName.split(".").pop() as string;
  const bufferedPositions = await getGeoPositionsArray(path, ext);
  return await initMesh(bufferedPositions, options);
}

export async function scaleMesh(
  model: Model,
  {
    scale,
    pathCollections,
    sectionCurves,
    columnCurves,
    stitchDensity,
  }: {
    scale: number;
    pathCollections: PathCollection[];
    columnCurves: ColumnCurve[];
    sectionCurves: SectionCurve[];
    stitchDensity: {
      wale: number;
      course: number;
    };
  },
): Promise<{
  mesh: Mesh;
  pathCollections: PathCollection[];
  columnCurves: ColumnCurve[];
  sectionCurves: SectionCurve[];
}> {
  const result = await getV3dApi().scaleMesh(model.mesh3DBase, {
    scale,
    guideCurve: findGuideCurve(Object.values(pathCollections)),
    curves: findUnusedAndGoringCurves(Object.values(pathCollections)),
    zones: computeZonesFromPathCollections(Object.values(pathCollections), model),
    columnAnchors: columnCurves.map((c) => c.points[0]),
    sectionAnchors: sectionCurves.map((c) => c.points[0]),
    stitchDensity,
  });

  return {
    mesh: getMeshFromGeometry(result),
    pathCollections: getPathCollectionsFromLayers(pathCollections, result),
    columnCurves: "columnCurves" in result ? getCurvesFromGridCurves(columnCurves, result.columnCurves) : [],
    sectionCurves: "sectionCurves" in result ? getCurvesFromGridCurves(sectionCurves, result.sectionCurves) : [],
  };
}

export async function getMeshPayload(
  model: Model,
  guideSource: PathCollection,
  pathCollections: PathCollection[],
  columnAnchors: Vector3[],
  sectionAnchors: Vector3[],
  stitchDensity: {
    wale: number;
    course: number;
  },
) {
  const guideCurve = {
    points: guideSource.points,
    controlVectors: guideSource.controlVectors,
  };
  const zones = computeZonesFromPathCollections(pathCollections, model);
  const goring = findGoringCurves(pathCollections);
  return await getV3dApi().getMeshPayload(model.mesh3DBase, {
    guideCurve,
    zones,
    goring,
    columnAnchors,
    sectionAnchors,
    stitchDensity,
  });
}

export function load3dmFile(file: File): Promise<File3dm> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = function (evt) {
      if (evt.target?.result) {
        const array = new Uint8Array(evt.target.result as ArrayBuffer);
        // @ts-expect-error: Incorrect type definition in module.
        const file3dm = window.rhino!.File3dm.fromByteArray(array);
        resolve(file3dm);
      }
    };
    reader.onerror = function () {
      reject(new Error(`Could not read ${file.name}`));
    };
  });
}
