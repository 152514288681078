import { Project } from "@models/backend";
import { useEffect, useMemo } from "react";
import { MeshBasicMaterial } from "three";
import { Process } from "@models/project";
import { workspace3dTokens } from "@fragments/project/workspace-3d/workspace-3d-tokens";
import { useUpdateKnitMesh } from "@hooks";
import { useModelsStore } from "@state";

export interface KnitMeshProps {
  project: Project;
  process: Process;
  visible: boolean;
}

function KnitMeshViewer({ process: { mesh, quadMesh }, visible }: KnitMeshProps) {
  const { updateKnitMesh } = useUpdateKnitMesh();

  const { knitMeshDirty } = useModelsStore((s) => s);

  const quadMaterial = useMemo(() => {
    return new MeshBasicMaterial({
      ...workspace3dTokens.knitMesh.quadMaterial,
    });
  }, [mesh, quadMesh]);

  useEffect(() => {
    const update = async () => {
      await updateKnitMesh();
    };

    if (knitMeshDirty && visible) {
      update();
    }
  }, [knitMeshDirty, visible]);

  return (
    <group>
      {mesh && <mesh name="model" geometry={mesh.geometry} material={mesh.material} visible={visible} />}
      {quadMesh && <mesh name="model" geometry={quadMesh.geometry} material={quadMaterial} visible={visible} />}
    </group>
  );
}

export default KnitMeshViewer;
